import { browseFile } from "nq";
import BaseListPresenter from "../../../base/BaseListPresenter";

class GroupsListPresenter extends BaseListPresenter {
  constructor(
    view,
    findObjectUseCase,
    countObjectUseCase,
    upsertUseCase,
    deleteObjectUseCase
  ) {
    super(
      view,
      findObjectUseCase,
      countObjectUseCase,
      upsertUseCase,
      deleteObjectUseCase
    );
    this.upsertUseCase = upsertUseCase;
    this.deleteObjectUseCase = deleteObjectUseCase;
  }

  init() {
    this.limit = 20;
    this.where = {};
    this.search = {};
    this.filter = {};
    this.filterDate = {};
    this.filterList = {};
    this.filterStatus = { $in: ["ACTIVE", "INACTIVE"] } || "";
    // this.filterStatusX = { $in: ["ACTIVE", "INACTIVE"] };
    this.getObjects();
    this.include = ["all"];
    this.keys = [];
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();
  }

  createQuery() {
    const skip = (this.current - 1) * this.limit;
    const query = {
      limit: this.limit,
      skip: skip,
      where: {
        ...this.where,
        ...this.search,
        ...this.filter,
        ...this.filterDate,
        ...this.filterList,
        status: this.filterStatus,
      },

      include: this.include,
    };
    if (this.sort) {
      query.sort = this.sort;
    }
    const keys = this.keys || this.view.getKeys() || [];
    if (keys.length > 0) {
      query.keys = keys;
    }
    return query;
  }

  async findObjects() {
    const user = this.view.getCurrentUser();
    const collection = this.view.getCollectionName();
    let query;

    query = this.createQuery();

    try {
      this.showProgress();
      this.findObjectUseCase.abort();
      this.objects = [];
      this.view.setObjects([]);
      const objects = await this.findObjectUseCase.execute(collection, query);
      this.objects = this.objects.concat(objects);
      this.view.setTotal(this.objects.length);
      this.view.setObjects(this.objects);
      this.hideProgress();
    } catch (error) {
      this.hideProgress();
      this.view.showError(error);
    }
    this.progress = false;
  }
  filterListing(where) {
    this.reset();
    this.filterList = where;
    this.getObjects();
  }

  onChangeDate(where) {
    this.filterDate = where;
    this.getObjects();
  }

  onChangeObject(objects) {
    this.change = objects;
    this.object = objects;
    console.log("this.change", this.change);
    console.log("this.object", this.object);
  }

  onClickAdd() {
    const collection = this.view.getCollectionName();
    this.view.navigateTo("/groups/form");
  }

  onClickItem(index) {
    const object = this.objects[index];
    const collection = this.view.getCollectionName();
    this.view.navigateTo("/groups/form/" + object.id);
  }
  onClickView(index) {
    const object = this.objects[index];
    const collection = this.view.getCollectionName();
    this.view.navigateTo("/groups/users/" + object.name + "/" + object.id);
  }
  onClickExport() {
    this.view.exportPDF();
  }

  async onClickStatus(index, status) {
    const collection = this.view.getCollectionName();
    const object = this.objects[index];
    object.status = status;
    try {
      const data = await this.upsertUseCase.execute(collection, object);
      if (data.status === object.status) {
        this.objects[index] = object;
        this.view.setObjects(this.objects);
      }
    } catch (error) {
      this.view.showError(error);
    }
  }

  onChangeStatus(value) {
    this.reset();
    console.log("valval", value.value);
    this.filterStatus = value.value;
    this.getObjects();
  }
  onChangeStatusX(value) {
    // this.reset();
    // this.getObjects();
    this.view.reload();
    //

    // this.filterStatusX = this.objects;
    // this.getObjects();
  }
}

export default GroupsListPresenter;
