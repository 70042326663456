import dialog from "../../components/Modal/dialog";
import classNames from "../../classNames";
class SignInPresenter {
  constructor(view, signInUseCase) {
    this.view = view;
    this.signInUseCase = signInUseCase;
    this.change = {};
  }

  onChange(value, field) {
    this.change[field] = value;
  }

  async submit() {
    try {
      // const masterKey = this.view.getMasterKey();
      const masterKey = "97e3fa4c-cbcb-4104-b6d0-52ee25aa2245";
      const user = {
        ...this.change,
        masterKey,
      };

      this.view.showProgress();

      const signedInUser = await this.signInUseCase.execute(user);
      console.log("sign", signedInUser);

      if (
        signedInUser.roles.some((role) => role.name === "Admin") &&
        signedInUser.status !== "INACTIVE"
      ) {
        this.view.navigateTo("/users");
      } else if (signedInUser.status !== "INACTIVE") {
        // this.view.navigateTo("/home");
        this.view.navigateTo("/quotations/form");
      } else {
        dialog.fire({
          html: (
            <div className="text-end">
              <div className="text-center">
                <i
                  className={classNames("bi bi-x-circle", "text-warning")}
                  style={{ fontSize: "5rem" }}
                ></i>
                <h4 className="fw-bold">Login Failed</h4>
                <p className="m-0">This account is inactive.</p>
                <button
                  className="btn mb-2 mt-2 btn-light"
                  onClick={() => dialog.close()}
                >
                  Confirm
                </button>
              </div>
            </div>
          ),
          footer: false,
        });
      }

      // this.view.navigateTo("/users");
    } catch (error) {
      this.view.hideProgress();
      this.view.showError(error);
    }
  }
}

export default SignInPresenter;
