import BaseFormPresenter from "../../../base/BaseFormPresenter";

class ClientInfoPresenter extends BaseFormPresenter {
    onChangeObject(object) {
        this.change = object;
        this.object = object;

        return object;
    }

    async save() {
        if (this.object.id) {
            this.change.id = this.object.id;
        } else {
            this.change.acl = this.view.getAcl();
        }
    }

    async submit() {
        const collection = this.view.getCollectionName();
        if (this.object.id) {
            this.change.id = this.object.id;
        } else {
            this.change.acl = this.view.getAcl();
        }
        try {
            await this.upsertUseCase.execute(collection, this.change);
            this.change = {};
        } catch (error) {
            throw error; // rethrow the error to be caught by the caller
        }
        // if (Object.values(this.change).length === 0) {
        //     this.view.showSuccessSnackbar("Successfully saved!");
        //     return;
        // }
        // try {
        //     this.view.submitting();
        //     await this.save();
        //     this.view.submissionSuccess();
        //     this.view.showSuccessSnackbar("Standard Rate Created Successfully!");
        //     this.view.navigateTo("/standard-rates/" + rate);
        // } catch (error) {
        //     this.view.submissionError(error);
        //     this.view.showError(error);
        // }
    }
}

export default ClientInfoPresenter;
