import React from "react";
import QuotationsListPresenter from "./QuotationsListPresenter";
import withRouter from "../../../withRouter";
import NavBar from "../../../components/navbar";
import BaseListPage from "../../../base/BaseListPage";
import InputFactory from "../../../components/InputFactory";
import Table from "../../../components/Table";
import {
  countObjectUseCase,
  deleteObjectUseCase,
  findObjectUseCase,
  upsertUseCase,
} from "../../../usecases/object";
import { exportCSVUseCase } from "../../../usecases/csv/usecases";
import browseFile from "../../../browseFile";
import { InfiniteScroll, Button, Progress } from "nq-component";
import DeleteObjectUseCase from "../../../usecases/object/DeleteObjectUseCase";
import DateRange from "../../../components/DateRange";
import Select from "react-select";
import QuotationTable from "../../../components/QuotationTable";
class QuotationsListPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new QuotationsListPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase(),
      deleteObjectUseCase(),
      upsertUseCase(),
      exportCSVUseCase()
    );
    this.state = {
      isOffcanvasOpen: false,
      objects: [],
      selected: [],
      sendingToOffice: false,
      isSent: false,
    };
  }
  componentDidMount() {
    this.presenter.componentDidMount();
    this.filterList();
  }
  filterList() {
    const user = this.getCurrentUser();
    const role = this.getCurrentRoles();
    if (role.some((role) => role.name === "admin")) {
      this.presenter.filterListing({});
    } else {
      this.presenter.filterListing({});
    }
  }
  getCollectionName() {
    return "quotations";
  }
  onChangeObject(objects, index) {
    this.presenter.onChangeObject(objects, index);
  }
  onClickAdd() {
    this.presenter.onClickAdd();
  }

  onClickItem(index, status) {
    this.presenter.onClickItem(index, status);
  }

  onSendToOfficer = (index, field) => {
    this.presenter.onSendOfficer(index, field);
    this.setState({ sendingToOffice: true });
  };

  onClickImport() {
    browseFile("*").then((files) => {
      if (files.length > 0) {
        const file = files[0];
        this.presenter.onClickImport(file);
      }
    });
  }

  onCollapse(index, object) {
    const user = this.getCurrentUser();
    const role = this.getCurrentRoles();
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const options = { year: "numeric", month: "long", day: "2-digit" };
      return date.toLocaleDateString("en-US", options);
    };
    return (
      <div>
        <div className="d-flex">
          <ul className="list-unstyled ms-1 text-truncate">
            {/* <li>
              <span className="ms-2 fw-light fw-bold">Customer Name: </span>
              <span className="text-nowrap">{object.customerName}</span>
            </li> */}
            <li>
              <span className="ms-2 fw-light fw-bold">Vehicle Details: </span>
              <span className="text-nowrap">
                {" "}
                {object?.carCompany} {object?.brand} {object.model}
              </span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">Date: </span>
              <span className="text-nowrap">
                {formatDate(object.createdAt)}
              </span>
            </li>
          </ul>
        </div>
        {/* {user.roles.some(
          (role) => role.name === "Special Agent" || role.name === "Admin"
        ) && ( */}
        {object.status === "done" ? null : (
          <button
            // onClick={this.onClickItem.bind(this, index)}
            onClick={() => this.onClickItem(index, object.status)}
            className="btn btn-primary me-2"
            style={{ backgroundColor: "#014900", border: 0 }}
          >
            {object.status === "sent" ? "VIEW" : "EDIT"}
          </button>
        )}
        {object.status === "sent" && (
          <button
            onClick={this.onSendToOfficer.bind(this, index)}
            className={`btn btn-primary`}
            style={{ backgroundColor: "#014900" }}
          >
            {this.state.sendingToOffice ? (
              <span
                style={{ height: 16, width: 16 }}
                className={`${this.state.sendingToOffice && "spinner-border"}`}
              ></span>
            ) : (
              <span className="">SEND TO OFFICER</span>
            )}
          </button>
        )}
        {/* )} */}
      </div>
    );
  }
  toggleOffcanvas = () => {
    this.setState((prevState) => ({
      isOffcanvasOpen: !prevState.isOffcanvasOpen,
    }));
  };
  // onClickDeleteSelected() {
  //   const deleteS = new DeleteObjectUseCase();
  //   const { selected } = this.state;

  //   if (selected.length > 1) {
  //     selected.map((item) => {
  //       deleteS.execute("quotations", item.id);
  //     });
  //   } else {
  //     console.log(selected[0].id);
  //     deleteS.execute("quotations", selected[0].id);
  //   }

  //   this.reload();
  // }
  onClickDeleteSelected() {
    const deleteS = new DeleteObjectUseCase();
    const { selected } = this.state;

    // Filter out items that are not in "draft" status
    const itemsToDelete = selected.filter((item) => item.status === "draft");

    if (itemsToDelete.length > 0) {
      itemsToDelete.forEach((item) => {
        deleteS.execute("quotations", item.id);
      });

      this.reload();
    } else {
      console.log("No items with status 'draft' to delete.");
    }
  }

  onChangeDate(where) {
    console.log("where date", where);
    this.presenter.onChangeDate(where);
  }

  onChangeStatus(value) {
    this.setState({ status: value });
    this.presenter.onChangeStatus(value);
  }

  render() {
    const { isOffcanvasOpen } = this.state;

    const schema = this.getSchema(this.getCollectionName());
    const { objects, selected, total, count, progress } = this.state;
    console.log("SELECTED", this.state.selected);
    console.log("ob", objects);
    const user = this.getCurrentUser();
    console.log("user", user);
    const role = this.getCurrentRoles();

    if (!schema) return <Progress />;
    return (
      <>
        <NavBar />
        <div className="overflow-auto">
          <InfiniteScroll
            className="h-100"
            loadMore={this.loadMore.bind(this)}
            hasMore={!progress && count > objects.length}
          >
            <div className="p-3 p-lg-4">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="fw-bold mt-3 text-capitalize">Quotations</h1>
                {selected.length > 0 ? (
                  <div>
                    <span className="ms-2">Selected: </span>
                    <span className="fs-sm text-nowrap">{selected.length}</span>
                    <span className="ms-1">of </span>
                    <span className="fs-sm text-nowrap">{count}</span>
                  </div>
                ) : (
                  <div>
                    <span className="ms-2">Total: </span>
                    <span className="fs-sm text-nowrap">{objects.length}</span>
                    <span className="ms-1">of </span>
                    <span className="fs-sm text-nowrap">{count}</span>
                  </div>
                )}

                <div className="d-block d-md-none mt-2">
                  <div className="d-flex align-items-center ">
                    <i
                      className="bi bi-filter"
                      style={{ fontSize: "25px", color: "#014900" }}
                      onClick={this.toggleOffcanvas}
                      aria-controls="offcanvasRight"
                    ></i>
                  </div>

                  <div
                    className={`offcanvas offcanvas-end ${
                      isOffcanvasOpen ? "show" : ""
                    }`}
                    tabIndex="-1"
                    id="offcanvasRight"
                    aria-labelledby="offcanvasRightLabel"
                    style={{
                      visibility: isOffcanvasOpen ? "visible" : "hidden",
                    }}
                  >
                    <div
                      className="offcanvas-header"
                      style={{ backgroundColor: "#014900" }}
                    >
                      <h5
                        className="offcanvas-title text-white"
                        id="offcanvasRightLabel"
                      >
                        Filters
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={this.toggleOffcanvas}
                      ></button>
                    </div>
                    <div className="offcanvas-body">
                      <div className=" mt-3">
                        {Object.keys(schema.filters || {}).map((field) => {
                          let { type, ...options } = schema.filters[field];

                          return (
                            <div className="mb-2">
                              <InputFactory
                                key={field}
                                className="ms-1"
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter.bind(this, type)}
                                {...options}
                              />
                            </div>
                          );
                        })}

                        {/* <DateRange
                          onChange={this.onChangeDate.bind(this)}
                          field="date"
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-md-flex d-none mt-3 justify-content-between align-items-center ">
                <div className="d-flex">
                  {Object.keys(schema.filters || {}).map((field) => {
                    let { type, ...options } = schema.filters[field];

                    return (
                      <InputFactory
                        key={field}
                        className="ms-1"
                        type={type}
                        field={field}
                        where={{}}
                        onChange={this.onChangeFilter.bind(this, type)}
                        {...options}
                      />
                    );
                  })}
                  <Select
                    className="basic-single col-3"
                    classNamePrefix="select"
                    isClearable={true}
                    isSearchable={true}
                    name="brand"
                    options={["sent", "draft", "done"].map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    onChange={(value) => this.onChangeStatus(value)}
                    placeholder="Select Status"
                  />
                  <DateRange
                    onChange={this.onChangeDate.bind(this)}
                    field="createdAt"
                  />
                </div>

                {/* {this.state.selected.length > 0 && (
                  <button
                    className="btn btn-danger"
                    aria-expanded="false"
                    onClick={this.onClickDeleteSelected.bind(this)}
                  >
                    <i className="bi bi-trash"></i>Delete
                  </button>
                )} */}
                {this.state.selected.length > 0 &&
                  !this.state.selected.some(
                    (item) => item.status === "done" || item.status === "sent"
                  ) && (
                    <button
                      className="btn btn-danger"
                      aria-expanded="false"
                      onClick={this.onClickDeleteSelected.bind(this)}
                    >
                      <i className="bi bi-trash"></i>Delete
                    </button>
                  )}
              </div>

              <QuotationTable
                fields={schema.fields}
                groups={schema.groups}
                objects={objects}
                collapsable
                selectable
                excludeFields={Object.keys(schema.fields).reduce(
                  (acc, key) => {
                    const options = schema.fields[key];
                    if (options.read === false) {
                      acc.push(key);
                    }
                    switch (options._type || options.type) {
                      case "Relation":
                      case "Array":
                      case "Object":
                      case "File":
                        acc.push(key);
                        break;
                      default:
                    }
                    return acc;
                  },
                  [
                    "acl",
                    "password",
                    "br",
                    "or",
                    "items",
                    "id",
                    // "createdAt",
                    "username",
                    "email",
                    "year",
                    "brand",
                    "model",
                    "variant",
                    "roadAssist",
                    "commissionRate",
                    "emailAddress",
                    "mobileNo",
                    "fileAttachment",
                    "carCompany",
                    "status",
                  ]
                )}
                selected={selected}
                onSelect={this.onSelect.bind(this)}
                onSelectAll={this.onSelectAll.bind(this)}
                progress={progress}
                onClickItem={this.onClickItem.bind(this)}
                onCollapse={this.onCollapse.bind(this)}
                className="mt-3"
              />
            </div>
            <div className="position-fixed bottom-0 end-0 m-4">
              <Button
                className="btn shadow-sm"
                onClick={this.onClickAdd.bind(this)}
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "25px",
                  backgroundColor: "#014900",
                }}
              >
                <i className="bi bi-plus text-white" />
              </Button>
            </div>
          </InfiniteScroll>
        </div>
      </>
    );
  }
}

export default withRouter(QuotationsListPage);
