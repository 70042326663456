import React from "react";
import { Button } from "nq-component";
import BaseListPage from "../../../base/BaseListPage";
import withRouter from "../../../withRouter";
import FindObjectUseCase from "../../../usecases/object/FindObjectUseCase";
import Progress from "../../../components/Progress";

class ComputeNewPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      year: "",
      brand: "",
      model: "",
      roadAssist: false,
      commissionRate: "",
      commissionRate1: "",
      commissionRate2: "",
      fvm: null,
      quotations: [],

      autoPA: "",
      bodyInjure: "",
      propertyDmg: "",

      autoPA_rate: "",
      bodyInjure_rate: "",
      propertyDmg_rate: "",

      basicPrem1: 0,
      basicPrem2: 0,
      totalGrossPrem1: "",
      totalGrossPrem2: "",
      basicwithAON: "",
      basicwithoutAON: "",
      commissionRateODTON: "",

      isSent: false,
      premiumRateAON: "",
      premiumRate: "",
    };
  }

  async componentDidMount() {
    const hasRefreshed = localStorage.getItem("hasRefreshed");
    if (!hasRefreshed) {
      // Set the flag to prevent continuous refresh
      localStorage.setItem("hasRefreshed", "true");

      // Refresh the page
      // window.location.reload();
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      localStorage.removeItem("hasRefreshed");
      this.setState({ loading: false });
      // setTimeout(() => {
      //    // Hide loading indicator after delay
      // }, 1000);

      const user = this.getCurrentUser();
      const get = new FindObjectUseCase();

      const data = localStorage.getItem("quote");
      const autoPAString = localStorage.getItem("autoPA");
      console.log("bkt wala", autoPAString);

      let converted = JSON.parse(data);
      console.log("converted", converted);

      converted = {
        ...converted,
        commissionRate: converted.commissionRate.replace("-", "").split(" ")[0],
        commissionRate1: converted.commissionRate
          .replace("-", "")
          .split(" ")[2],
      };

      const [LGT, config, special_others, quotations] = await Promise.all([
        get.execute("branches", { where: { id: user.branchName.id } }),
        get.execute("configuration"),
        user.roles[0].name === "Standard Agent"
          ? get.execute("standard_others")
          : user.roles[0].name === "Special Agent"
          ? get.execute("special_others")
          : null,
        get.execute("quotations", { where: { id: converted.id } }),
      ]);

      this.setState({ quotations });

      console.log("quot", quotations);
      console.log("LGT", LGT);

      if (quotations[0]?.status == "sent") {
        this.setState({ isSent: true });
      }
      console.log("bodyInjure", special_others);

      if (special_others) {
        special_others.map((item) => {
          if (item.name === "Bodily Injury (VTPL-BI)") {
            this.setState({
              bodyInjure: item.sum,
              bodyInjure_rate: item.rate,
            });
          }
          if (item.name === "Auto Personal Accident Insurance") {
            this.setState({
              autoPA: item.sum,
              autoPA_rate: item.rate,
            });
          }
          if (item.name === "Property Damage (VTPL-PD)") {
            this.setState({
              propertyDmg: item.sum,
              propertyDmg_rate: item.rate,
            });
          }
        });
      }

      // console.log(converted.roadAssist)

      const { bodyInjure_rate, propertyDmg_rate } = this.state;

      this.setState(converted, async () => {
        console.log(
          "this.state.commissionRate",
          parseFloat(this.state.commissionRate1.replace("%", ""))
        );
        const findOdt = async () => {
          const res =
            user.roles[0].name === "Standard Agent"
              ? await get.execute("standard_odta", {
                  where: {
                    premiumRateAON: this.state.commissionRate,
                    commissionRateODTON: this.state.commissionRate1.replace(
                      "%",
                      ""
                    ),
                  },
                })
              : user.roles[0].name === "Special Agent"
              ? await get.execute("special_odta", {
                  where: {
                    premiumRateAON: this.state.commissionRate,
                    commissionRateODTON: this.state.commissionRate1.replace(
                      "%",
                      ""
                    ),
                  },
                })
              : null;

          console.log("converted.comm", converted.carBrand);
          console.log("res", res);
          const { fvm } = this.state;
          console.log("fvm", fvm);
          const newFvm = fvm?.includes(",")
            ? fvm.replace(/,/g, "").trim()
            : fvm;
          console.log("newFvm", newFvm);
          console.log("resAON", res[0]?.premiumRateAON);
          this.setState({ premiumRateAON: res[0]?.premiumRateAON });
          this.setState({ premiumRate: res[0]?.premiumRate });
          const result =
            Number(newFvm) * (Number(res[0]?.premiumRateAON) / 100);
          const result2 = Number(newFvm) * (Number(res[0]?.premiumRate) / 100);

          console.log("result amount", result);

          const formattedResult = result.toLocaleString("en-US");
          const formattedResult2 = result2.toLocaleString("en-US");

          this.setState({ commissionRateODTON: res[0]?.commissionRateODTON });

          this.setState({ commissionRate2: formattedResult2 });

          console.log("formattedresult:", formattedResult);

          this.setState({ commissionRate: formattedResult }, () => {
            const sanitizedFvm = this.state.fvm.replaceAll(/,/g, "");

            console.log("BI", Number(bodyInjure_rate));

            // const basicPrem1 =
            //   result +
            //   Number(this.state.bodyInjure_rate) +
            //   Number(this.state.propertyDmg_rate) +
            //   (Number(sanitizedFvm) * Number(res[0]?.actOfNature)) / 100;
            const sanitizeAndConvert = (value) =>
              !isNaN(Number(value.replace(/[^0-9.-]/g, "")))
                ? Number(value.replace(/[^0-9.-]/g, ""))
                : 0;

            const bodyInjureRate = sanitizeAndConvert(
              this.state.bodyInjure_rate
            );
            const propertyDmgRate = sanitizeAndConvert(
              this.state.propertyDmg_rate
            );
            const sanitizedFvmValue = sanitizeAndConvert(sanitizedFvm);
            const actOfNatureValue = sanitizeAndConvert(res[0]?.actOfNature);

            // const autoPA = JSON.parse(localStorage.getItem("autoPA"));
            // console.log("auto", autoPA);

            // let autoPA = null;

            // if (autoPAString) {
            //   try {
            //     autoPA = JSON.parse(autoPAString);
            //   } catch (e) {
            //     console.error("Error parsing autoPA from localStorage:", e);
            //     autoPA = null; // Or set a default value
            //   }
            // }
            const capacity = Number(converted.capacity);
            console.log("cccccccc", capacity);
            const freePassenger = Number(user.freePassenger);
            console.log("freePassenger", freePassenger);
            const totalCapacity =
              freePassenger >= capacity ? 0 : capacity - freePassenger;
            const absoluteCapacity = Math.abs(totalCapacity);

            const amount_autoPA = !isNaN(
              Number(this.state?.autoPA.replace(/[^0-9.-]/g, ""))
            )
              ? (
                  Number(this.state?.autoPA.replace(/[^0-9.-]/g, "")) *
                  absoluteCapacity
                ).toLocaleString("en-US")
              : "Error: Invalid number";
            const amount_autoPAfinal = (
              (Number(amount_autoPA.replace(/[^0-9.-]/g, "")) *
                this.state?.autoPA_rate) /
              100
            ).toLocaleString("en-US");

            console.log("auto", amount_autoPAfinal);

            const basicPrem1 =
              result +
              bodyInjureRate +
              propertyDmgRate +
              (sanitizedFvmValue * actOfNatureValue) / 100;
            // const basicPrem2 =
            //   result2 +
            //   Number(this.state.bodyInjure_rate) +
            //   Number(this.state.propertyDmg_rate);
            // Number(sanitizedFvm);
            const bodyInjureRate2 = sanitizeAndConvert(
              this.state.bodyInjure_rate
            );
            const propertyDmgRate2 = sanitizeAndConvert(
              this.state.propertyDmg_rate
            );

            const basicPrem2 = result2 + bodyInjureRate2 + propertyDmgRate2;

            console.log("check", Number(LGT[0]?.lgt));

            // const amount_LGT =
            //   ((Number(basicPrem1) + Number(autoPA)) * Number(LGT[0]?.lgt)) /
            //   100;

            // const amount_LGT2 =
            //   ((Number(basicPrem2) + Number(autoPA)) * Number(LGT[0]?.lgt)) /
            //   100;
            const lgtValue = Number(LGT[0]?.lgt) || 0;
            const basicPrem1Value = Number(basicPrem1) || 0;
            const basicPrem2Value = Number(basicPrem2) || 0;
            const autoPAValue =
              Number(amount_autoPAfinal) || amount_autoPAfinal || 0;
            const docStampValue = Number(config[0]?.docStamp) || 0;
            const eVatValue = Number(config[0]?.eVat) || 0;

            console.log("Basic1", basicPrem1Value);
            console.log("autoPa", Number(autoPAValue));

            console.log("docStampValue", docStampValue);
            console.log("eVatValue", eVatValue);
            console.log("LGTVALUE", lgtValue);

            // const amount_LGT =
            //   ((basicPrem1Value + autoPAValue) * lgtValue) / 100;
            // const amount_LGT2 =
            //   ((basicPrem2Value + autoPAValue) * lgtValue) / 100;

            // console.log("AUTO AUTO", autoPA);

            // const amount_docStamp =
            //   (docStampValue * (basicPrem1Value + autoPAValue)) / 100;
            // const amount_docStamp2 =
            //   (docStampValue * (basicPrem2Value + autoPAValue)) / 100;

            // // Calculate amount_EVAT and amount_EVAT2
            // const amount_EVAT =
            //   (eVatValue * (basicPrem1Value + autoPAValue)) / 100;
            // const amount_EVAT2 =
            //   (eVatValue * (basicPrem2Value + autoPAValue)) / 100;
            // Calculate amount_LGT and amount_LGT2 based on LGT value
            const amount_LGT =
              (basicPrem1Value + Number(autoPAValue)) * (lgtValue / 100);
            const amount_LGT2 =
              (basicPrem2Value + Number(autoPAValue)) * (lgtValue / 100);

            console.log("AUTO AUTO", autoPAValue);

            // Calculate amount_docStamp and amount_docStamp2 based on docStampValue
            const amount_docStamp =
              (basicPrem1Value + Number(autoPAValue)) * (docStampValue / 100);
            const amount_docStamp2 =
              (basicPrem2Value + Number(autoPAValue)) * (docStampValue / 100);

            // Calculate amount_EVAT and amount_EVAT2 based on eVatValue
            const amount_EVAT =
              (basicPrem1Value + Number(autoPAValue)) * (eVatValue / 100);
            const amount_EVAT2 =
              (basicPrem2Value + Number(autoPAValue)) * (eVatValue / 100);

            console.log("amount_LGT", amount_LGT);

            // const totalGrossPrem1_ = (
            //   parseFloat(parseFloat(basicPrem1).toFixed(2)) +
            //   parseFloat(parseFloat(amount_LGT.toFixed(2))) +
            //   parseFloat(parseFloat(amount_EVAT).toFixed(2)) +
            //   parseFloat(parseFloat(amount_docStamp).toFixed(2))
            // ).toFixed(2);
            const totalGrossPrem1_ = (
              parseFloat(parseFloat(basicPrem1).toFixed(2)) +
              parseFloat(Math.round(amount_LGT * 100) / 100) +
              parseFloat(parseFloat(amount_EVAT).toFixed(2)) +
              parseFloat(parseFloat(amount_docStamp).toFixed(2))
            ).toFixed(2);

            console.log(
              "basicPrem1:",
              basicPrem1,
              "amount_LGT:",
              amount_LGT,
              // parseFloat(Math.round(amount_LGT * 100) / 100),
              "amount_EVAT:",
              amount_EVAT,
              "amount_docStamp:",
              parseFloat(parseFloat(amount_docStamp).toFixed(2))
            );
            console.log("TGP1", totalGrossPrem1_);

            const totalGrossPrem2_ = (
              basicPrem2 +
              parseFloat(Math.round(amount_LGT2 * 100) / 100) +
              amount_EVAT2 +
              amount_docStamp2
            ).toLocaleString("en-US");
            console.log("GETETEGETEGETGETE", totalGrossPrem2_);
            console.log("amount_LGT2", basicPrem2);
            console.log("amount_LGT2", amount_LGT2);
            console.log("amount_LGT2", amount_EVAT2);
            console.log("amount_LGT2", amount_docStamp2);

            this.setState(
              {
                totalGrossPrem1: totalGrossPrem1_.toLocaleString("en-US"),
                totalGrossPrem2: totalGrossPrem2_.toLocaleString("en-US"),
                basicPrem1: basicPrem1.toLocaleString("en-US"),
                basicPrem2: basicPrem2.toLocaleString("en-US"),
              },
              () => {
                const {
                  fvm,
                  bodyInjure,
                  propertyDmg,
                  autoPA,
                  autoPA_rate,
                  basicPrem1,
                  basicPrem2,
                  commissionRate,
                  commissionRate2,
                  bodyInjure_rate,
                  propertyDmg_rate,
                  model,
                  totalGrossPrem1,
                  totalGrossPrem2,
                  commissionRateODTON,
                  premiumRateAON,
                  premiumRate,
                } = this.state;

                let sanitizedFvm = Number(fvm.replaceAll(/,/g, ""));

                const sum_BodyInjury = !isNaN(
                  Number(bodyInjure.replace(/[^0-9.-]/g, ""))
                )
                  ? Number(bodyInjure.replace(/[^0-9.-]/g, "")).toLocaleString(
                      "en-US"
                    )
                  : "Error: Invalid number";
                console.log("sumBody", sum_BodyInjury);

                const amount_BodyInjury = !isNaN(
                  Number(bodyInjure_rate.replace(/[^0-9.-]/g, ""))
                )
                  ? Number(
                      bodyInjure_rate.replace(/[^0-9.-]/g, "")
                    ).toLocaleString("en-US")
                  : "Error: Invalid number";

                const formatNumberCurrency = (value) =>
                  !isNaN(Number(value.replace(/[^0-9.-]/g, "")))
                    ? Number(value.replace(/[^0-9.-]/g, "")).toLocaleString(
                        "en-US"
                      )
                    : "Error: Invalid number";

                const sum_propertyDmg = formatNumberCurrency(propertyDmg);
                const amount_propertyDmg =
                  formatNumberCurrency(propertyDmg_rate);

                const user = this.getCurrentUser();
                console.log("get passanger user", user);

                const capacity = Number(converted.capacity);
                console.log("cccccccc", capacity);
                const freePassenger = Number(user.freePassenger);
                console.log("freePassenger", freePassenger);
                const totalCapacity =
                  freePassenger >= capacity ? 0 : capacity - freePassenger;
                const absoluteCapacity = Math.abs(totalCapacity);
                console.log("auto", autoPA);
                console.log("autorate", autoPA_rate);

                const sum_autoPA = !isNaN(
                  Number(autoPA.replace(/[^0-9.-]/g, ""))
                )
                  ? (
                      Number(autoPA.replace(/[^0-9.-]/g, "")) * capacity
                    ).toLocaleString("en-US")
                  : "Error: Invalid number";

                const amount_autoPA = !isNaN(
                  Number(autoPA.replace(/[^0-9.-]/g, ""))
                )
                  ? (
                      Number(autoPA.replace(/[^0-9.-]/g, "")) * absoluteCapacity
                    ).toLocaleString("en-US")
                  : "Error: Invalid number";
                const amount_autoPAfinal = (
                  (Number(amount_autoPA.replace(/[^0-9.-]/g, "")) *
                    autoPA_rate) /
                  100
                ).toLocaleString("en-US");

                localStorage.setItem(
                  "autoPA",
                  JSON.stringify(amount_autoPAfinal)
                );

                const sum_AON = Number(sanitizedFvm).toLocaleString("en-US");
                const amount_AON1 = (
                  Number(sanitizedFvm) * Number(res[0]?.actOfNature / 100)
                ).toLocaleString("en-US");
                console.log("aonymous", res[0]?.actOfNature);
                console.log("sum_AON", sum_AON);
                console.log("amount_AON1", amount_AON1);
                sanitizedFvm = sanitizedFvm.toLocaleString("en-US");

                const amount_AON2 = sanitizedFvm;

                // const amount_LGT = (
                //   ((Number(basicPrem1.replace(/[^0-9.-]/g, "")) +
                //     Number(amount_autoPAfinal.replace(/[^0-9.-]/g, ""))) *
                //     Number(LGT[0]?.lgt)) /
                //   100
                // ).toLocaleString("en-US");
                console.log("basicPrem1Value", basicPrem1Value);
                console.log("autoPAValue", amount_autoPAfinal);
                console.log("lgtValue", lgtValue);
                // const amount_LGT =
                //   ((basicPrem1Value + autoPAValue) * lgtValue) / 100;
                // console.log("check 2", amount_LGT);
                // const amount_LGT =
                //   ((basicPrem1Value + autoPAValue) * lgtValue) / 100;
                // const amount_LGT =
                //   (basicPrem1Value + Number(amount_autoPAfinal)) *
                //   (lgtValue / 100);
                const amount_LGT =
                  Math.round(
                    (basicPrem1Value + Number(amount_autoPAfinal)) *
                      (lgtValue / 100) *
                      100
                  ) / 100;

                const amount_LGT_string = amount_LGT.toFixed(2); // Convert to string with two decimals
                console.log("check 2", amount_LGT);

                const amount_LGT2 = (
                  (Number(LGT[0]?.lgt) *
                    (Number(basicPrem2.replace(/[^0-9.-]/g, "")) +
                      Number(amount_autoPAfinal.replace(/[^0-9.-]/g, "")))) /
                  100
                ).toLocaleString("en-US");

                console.log("BASIC PRE", basicPrem1);

                const amount_docStamp =
                  ((Number(basicPrem1.replace(/[^0-9.-]/g, "")) +
                    Number(amount_autoPAfinal.replace(/[^0-9.-]/g, ""))) *
                    Number(config[0]?.docStamp)) /
                  100;

                const formatted_amount_docStamp =
                  amount_docStamp.toLocaleString("en-US");

                const amount_docStamp2 =
                  ((Number(basicPrem2.replace(/[^0-9.-]/g, "")) +
                    Number(amount_autoPAfinal.replace(/[^0-9.-]/g, ""))) *
                    Number(config[0]?.docStamp)) /
                  100;

                const formatted_amount_docStamp2 =
                  amount_docStamp2.toLocaleString("en-US");

                const amount_EVAT = (
                  ((Number(basicPrem1.replace(/[^0-9.-]/g, "")) +
                    Number(amount_autoPAfinal.replace(/[^0-9.-]/g, ""))) *
                    Number(config[0]?.eVat)) /
                  100
                ).toLocaleString("en-US");
                console.log(
                  "nnnnnnnnnnnnnnnnnn",
                  ((Number(basicPrem2.replace(/[^0-9.-]/g, "")) +
                    Number(amount_autoPAfinal.replace(/[^0-9.-]/g, ""))) *
                    Number(config[0]?.eVat)) /
                    100
                );
                const amount_EVAT2 = (
                  ((Number(basicPrem2.replace(/[^0-9.-]/g, "")) +
                    Number(amount_autoPAfinal.replace(/[^0-9.-]/g, ""))) *
                    Number(config[0]?.eVat)) /
                  100
                ).toLocaleString("en-US");
                console.log("evat2", amount_EVAT2);

                const amount_roadAssist = Number(
                  config[0]?.roadAssist
                ).toLocaleString("en-US");

                const compute = {
                  commissionRate: commissionRate,
                  commissionRate2: commissionRate2,
                  auto: sum_autoPA,
                  autoAmount: amount_autoPAfinal,
                  model: model,
                  sum_ownDmg: sanitizedFvm,
                  sum_theft: sanitizedFvm,
                  sum_bodyInjury: sum_BodyInjury,
                  amount_bodyInjury: amount_BodyInjury,

                  sum_propDmg: sum_propertyDmg,
                  amount_propertyDmg: amount_propertyDmg,

                  sum_AON: sum_AON,

                  amount_AON1: amount_AON1,
                  amount_AON2: amount_AON2,

                  amount_basicPrem1: basicPrem1,
                  amount_basicPrem2: basicPrem2,
                  docStamp: amount_docStamp,
                  docStamp2: amount_docStamp2,
                  eVAT: amount_EVAT,
                  eVAT2: amount_EVAT2,
                  lgt: amount_LGT,
                  lgt2: amount_LGT2,
                  lgtAddress: LGT[0]?.address,
                  lgtEmail: LGT[0]?.email,
                  lgttelephone: LGT[0]?.telephoneNo,
                  lgtwebsite: LGT[0]?.website,
                  roadAssist:
                    converted.roadAssist == false ? "0" : amount_roadAssist,

                  totalGrossPrem1: totalGrossPrem1,
                  totalGrossPrem2: totalGrossPrem2,
                  basicwithAON: basicPrem1,
                  basicwithoutAON: basicPrem2,
                  rateSelect: commissionRateODTON,
                  rateQuote: converted.commissionRate,
                  premiumRateAON: premiumRateAON,
                  premiumRate: premiumRate,
                };
                localStorage.setItem("compute", JSON.stringify(compute));

                const formatNumber = (number) => {
                  return Number(number).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  });
                };

                const formatStringNumber = (string) => {
                  const number = parseFloat(string?.replace(/,/g, "")); // Remove commas if present
                  return isNaN(number) ? string : formatNumber(number);
                };
                console.log("AYUN NA NGA", this.state.premiumRateAON);
                const basicPrems1 = (
                  Number(basicPrem1.replace(/[^0-9.-]/g, "")) +
                  Number(amount_autoPAfinal.replace(/[^0-9.-]/g, ""))
                ).toLocaleString("en-US");

                console.log("BASIC PREMIUM", basicPrem1);

                const basicPrems2 = (
                  Number(basicPrem2.replace(/[^0-9.-]/g, "")) +
                  Number(amount_autoPAfinal.replace(/[^0-9.-]/g, ""))
                ).toLocaleString("en-US");

                console.log(
                  "LALALALALLALALALLA",
                  formatStringNumber(basicPrems2)
                );

                const data1 = [
                  {
                    coverage: "Own Damage",
                    peso: "₱",
                    sumInsured: formatStringNumber(sanitizedFvm),
                    blank: "₱",
                    amount:
                      this.state.premiumRateAON === "0"
                        ? "0"
                        : formatStringNumber(commissionRate),
                  },
                  {
                    coverage: "Theft",
                    peso: "",
                    sumInsured: formatStringNumber(sanitizedFvm),
                    blank: "",
                    amount: "0",
                  },
                  {
                    coverage: "Bodily Injury",
                    peso: "",
                    sumInsured: formatStringNumber(sum_BodyInjury),
                    blank: "",
                    amount:
                      this.state.premiumRateAON === "0"
                        ? "0"
                        : formatStringNumber(amount_BodyInjury),
                  },
                  {
                    coverage: "Property Damage",
                    peso: "",
                    sumInsured: formatStringNumber(sum_propertyDmg),
                    blank: "",
                    amount:
                      this.state.premiumRateAON === "0"
                        ? "0"
                        : formatStringNumber(amount_propertyDmg),
                  },
                  {
                    coverage: "Auto PA",
                    peso: "",
                    sumInsured: formatStringNumber(sum_autoPA),
                    blank: "",
                    amount:
                      this.state.premiumRateAON === "0"
                        ? "0"
                        : formatStringNumber(amount_autoPAfinal),
                  },
                  {
                    coverage: "Acts of Nature (AON)",
                    peso: "",
                    sumInsured: formatStringNumber(sum_AON),
                    blank: "",
                    amount:
                      this.state.premiumRateAON === "0"
                        ? "0"
                        : formatStringNumber(amount_AON1),
                  },
                  {
                    coverage: "",
                    peso: "",
                    sumInsured: "",
                    blank: "",
                    amount: "━━━━━━━━━",
                  },
                  {
                    coverage: "",
                    peso: "",
                    sumInsured: "Basic Premium",
                    blank: "₱",
                    amount:
                      this.state.premiumRateAON === "0"
                        ? "0"
                        : formatStringNumber(basicPrems1),
                  },
                  {
                    coverage: "",
                    peso: "",
                    sumInsured: "Doc Stamps",
                    blank: "",
                    amount:
                      this.state.premiumRateAON === "0"
                        ? "0"
                        : formatStringNumber(formatted_amount_docStamp),
                  },
                  {
                    coverage: "",
                    peso: "",
                    sumInsured: "E-VAT",
                    blank: "",
                    amount:
                      this.state.premiumRateAON === "0"
                        ? "0"
                        : formatStringNumber(amount_EVAT),
                  },
                  {
                    coverage: "",
                    peso: "",
                    sumInsured: "LGT",
                    blank: "",
                    amount:
                      this.state.premiumRateAON === "0"
                        ? "0"
                        : formatStringNumber(amount_LGT_string),
                  },
                  {
                    coverage: "",
                    peso: "",
                    sumInsured: "24/7 Roadside Assistance",
                    blank: "",
                    amount:
                      this.state.premiumRateAON === "0"
                        ? "0"
                        : formatStringNumber(amount_roadAssist),
                  },
                ];

                const data2 = [
                  {
                    coverage: "Own Damage",
                    peso: "₱",
                    sumInsured: formatStringNumber(sanitizedFvm),
                    blank: "₱",
                    amount:
                      this.state.premiumRate === "0"
                        ? "0"
                        : formatStringNumber(commissionRate2),
                  },
                  {
                    coverage: "Theft",
                    peso: "",
                    sumInsured: formatStringNumber(sanitizedFvm),
                    blank: "",
                    amount: "0",
                  },
                  {
                    coverage: "Bodily Injury",
                    peso: "",
                    sumInsured: formatStringNumber(sum_BodyInjury),
                    blank: "",
                    amount:
                      this.state.premiumRate === "0"
                        ? "0"
                        : formatStringNumber(amount_BodyInjury),
                  },
                  {
                    coverage: "Property Damage",
                    peso: "",
                    sumInsured: formatStringNumber(sum_propertyDmg),
                    blank: "",
                    amount:
                      this.state.premiumRate === "0"
                        ? "0"
                        : formatStringNumber(amount_propertyDmg),
                  },
                  {
                    coverage: "Auto PA",
                    peso: "",
                    sumInsured: formatStringNumber(sum_autoPA),
                    blank: "",
                    amount:
                      this.state.premiumRate === "0"
                        ? "0"
                        : formatStringNumber(amount_autoPAfinal),
                  },
                  {
                    coverage: "                    ", // 20 spaces to match "Acts of Nature (AON)"
                    peso: "",
                    sumInsured: "",
                    blank: "",
                    amount: "",
                  },
                  {
                    coverage: "",
                    peso: "",
                    sumInsured: "",
                    blank: "",
                    amount: "━━━━━━━━━",
                  },
                  {
                    coverage: "",
                    peso: "",
                    sumInsured: "Basic Premium",
                    blank: "₱",
                    amount:
                      this.state.premiumRate === "0"
                        ? "0"
                        : formatStringNumber(basicPrems2),
                  },
                  {
                    coverage: "",
                    peso: "",
                    sumInsured: "Doc Stamps",
                    blank: "",
                    amount:
                      this.state.premiumRate === "0"
                        ? "0"
                        : formatStringNumber(formatted_amount_docStamp2),
                  },
                  {
                    coverage: "",
                    peso: "",
                    sumInsured: "E-VAT",
                    blank: "",
                    amount:
                      this.state.premiumRate === "0"
                        ? "0"
                        : formatStringNumber(amount_EVAT2),
                  },
                  {
                    coverage: "",
                    peso: "",
                    sumInsured: "LGT",
                    blank: "",
                    amount:
                      this.state.premiumRate === "0"
                        ? "0"
                        : formatStringNumber(amount_LGT2),
                  },
                  {
                    coverage: "",
                    peso: "",
                    sumInsured: "24/7 Roadside Assistance",
                    blank: "",
                    amount:
                      this.state.premiumRate === "0"
                        ? "0"
                        : formatStringNumber(amount_roadAssist),
                  },
                ];

                if (converted.roadAssist == false) {
                  data1.pop();
                  data2.pop();
                }

                this.setState({ data1 });
                this.setState({ data2 });
              }
            );
          });
        };

        findOdt();
      });
    }
  }

  render() {
    const needsToBold = [
      "Basic Premium",
      "Doc Stamps",
      "E-VAT",
      "LGT",
      "24/7 Roadside Assistance",
    ];
    const add = JSON.parse(localStorage.getItem("compute"));
    console.log("add: ", add);
    console.log("STATESSSSS DATA1", this.state.data1);
    console.log("STATESSSSS DATA2", this.state.data2);
    console.log(
      "Number(this.state?.totalGrossPrem1.re",
      Number(this.state?.totalGrossPrem1.replace(/,/g, ""))
    );
    const renderTable = (title, data) => (
      <div className="bg-white rounded p-4 w-100 mt-2 overflow-x-scroll">
        <p style={{ color: "#1A8326" }} className="fw-bold">
          {title}
        </p>
        <table className="table-light w-100 overflow-x-scroll">
          <thead className="text-white" style={{ background: "#1A8326" }}>
            <tr>
              <th className="p-2">Coverage</th>
              {/* <th className="p-2"></th> */}
              <th className="p-2 text-center">Sum Insured</th>
              {/* <th className="p-2"></th> */}
              <th className="p-2 text-center">Amount</th>
            </tr>
          </thead>
          <tbody className="w-100">
            {data?.map((row, index) => {
              return (
                <tr key={index}>
                  <td
                    style={{ width: "200px" }}
                    className={`text-start ${
                      needsToBold.includes(row.coverage) ? "fw-bold" : ""
                    }`}
                  >
                    {row.coverage}
                  </td>
                  {/* <td className={`${row.coverage == "" ? "text-start" : "text-end"}`}>{row.peso}</td> */}
                  <td
                    className={`${
                      row.coverage == "" ? "text-left fw-bold" : "text-right"
                    }`}
                    style={{ textAlign: row.coverage ? "end" : "left" }}
                  >
                    {row.peso} {row.sumInsured}
                  </td>
                  {/* <td className="text-end">{row.blank}</td> */}
                  <td
                    className={`text-left ${
                      row.coverage == "" ? "fw-bold" : ""
                    }`}
                    style={{ textAlign: row.coverage ? "end" : "end" }}
                  >
                    {/* {(row.coverage === "Basic Premium" || row.coverage === "Own Damage") && "₱"} */}
                    {row.blank} {row.amount}
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr className="text-white" style={{ background: "#1A8326" }}>
              <td className="p-2 fw-bold"></td>
              {/* <td className="p-2"></td> */}
              {/* <td className="p-2"></td> */}
              <td className="p-2">TOTAL GROSS PREMIUM</td>
              {/* <td className="p-2 fw-bold text-end">
                ₱{" "}
                {title.includes("1")
                  ? this.state?.totalGrossPrem1.toFixed(2)
                  : this.state?.totalGrossPrem2.toFixed(2)}
              </td> */}
              {/* <td className="p-2 fw-bold text-end">
                ₱{" "}
                {title.includes("1")
                  ? Number(
                      this.state?.totalGrossPrem1.replace(/,/g, "")
                    ).toFixed(2)
                  : Number(
                      this.state?.totalGrossPrem2.replace(/,/g, "")
                    ).toFixed(2)}
              </td> */}
              <td className="p-2 fw-bold text-end">
                ₱{" "}
                {title.includes("1")
                  ? this.state.premiumRateAON === "0"
                    ? "0"
                    : (
                        Number(this.state?.totalGrossPrem1.replace(/,/g, "")) +
                        Number(add?.autoAmount) +
                        Number(add?.roadAssist)
                      ).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                  : this.state.premiumRate === "0"
                  ? "0"
                  : (
                      Number(this.state?.totalGrossPrem2.replace(/,/g, "")) +
                      Number(add?.autoAmount) +
                      Number(add?.roadAssist)
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    );

    return (
      // <div className="p-5">
      //   <div className="overflow-auto">
      //     {this.state.loading ? null : <h1 className="fw-bold">Computation</h1>}
      //     {this.state?.quotations.length > 0 && (
      //       <div>
      //         <p className="mb-0">
      //           <span className="fw-bold"> Reference #:</span>
      //           <span> {this.state?.quotations[0]?.ref_no}</span>
      //         </p>
      //         <p className="mb-0">
      //           <span className="fw-bold"> Vehicle Details:</span>
      //           <span>
      //             {" "}
      //             {this.state?.quotations[0]?.year}{" "}
      //             {this.state?.quotations[0]?.carCompany}{" "}
      //             {this.state?.quotations[0]?.brand}{" "}
      //             {this.state?.quotations[0]?.model}
      //           </span>
      //         </p>
      //         <p className="">
      //           <span className="fw-bold"> Customer Name:</span>
      //           <span> {this.state?.quotations[0]?.customerName}</span>
      //         </p>
      //       </div>
      //     )}
      //   </div>

      //   {this.state.loading ? (
      //     <div
      //       className="d-flex justify-content-center align-items-center"
      //       style={{ marginTop: "280px" }}
      //     >
      //       <div className="card p-3">
      //         {" "}
      //         <Progress />
      //         <p className="fw-bold mt-2">
      //           Just a moment, we’re processing the data...
      //         </p>
      //       </div>
      //     </div>
      //   ) : (
      //     <div>
      //       {/* {renderTable("Option 1 - With AON", this.state.data1)} */}

      //       {renderTable("Option 1 - With AON", this.state.data1)}
      //       {renderTable("Option 2 - Without AON", this.state.data2)}
      //     </div>
      //   )}

      //   <div className="d-flex align-items-center justify-content-center">
      //     {this.state.loading ? null : (
      //       <div style={{ marginLeft: "auto" }}>
      //         <Button onClick={() => this.navigateBack()}>Cancel</Button>
      //         {!this.state.isSent && (
      //           <Button
      //             onClick={() => {
      //               this.navigateTo(`/quotations/client_info/form/`);
      //             }}
      //           >
      //             Confirm
      //           </Button>
      //         )}
      //       </div>
      //     )}
      //   </div>
      // </div>
      <div className="p-4 p-md-1 p-lg-5 p-sm-1">
        <div className="overflow-auto">
          {this.state.loading ? null : <h1 className="fw-bold">Computation</h1>}
          {this.state?.quotations.length > 0 && (
            <div>
              <p className="mb-0">
                <span className="fw-bold"> Reference #:</span>
                <span> {this.state?.quotations[0]?.ref_no}</span>
              </p>
              <p className="mb-0">
                <span className="fw-bold"> Vehicle Details:</span>
                <span>
                  {" "}
                  {this.state?.quotations[0]?.year}{" "}
                  {this.state?.quotations[0]?.carCompany}{" "}
                  {this.state?.quotations[0]?.brand}{" "}
                  {this.state?.quotations[0]?.model}
                </span>
              </p>
              <p className="">
                <span className="fw-bold"> Customer Name:</span>
                <span> {this.state?.quotations[0]?.customerName}</span>
              </p>
            </div>
          )}
        </div>

        {this.state.loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ marginTop: "280px" }}
          >
            <div className="card p-3">
              <Progress />
              <p className="fw-bold mt-2">
                Just a moment, we’re processing the data...
              </p>
            </div>
          </div>
        ) : (
          <div>
            {renderTable("Option 1 - With AON", this.state.data1)}
            {renderTable("Option 2 - Without AON", this.state.data2)}
          </div>
        )}

        <div className="d-flex align-items-center justify-content-center">
          {this.state.loading ? null : (
            <div style={{ marginLeft: "auto" }}>
              <Button onClick={() => this.navigateBack()}>Cancel</Button>
              {!this.state.isSent && (
                <Button
                  onClick={() =>
                    this.navigateTo(`/quotations/client_info/form/`)
                  }
                >
                  Confirm
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(ComputeNewPage);
