import BaseFormPresenter from "../../base/BaseFormPresenter";
import BaseListPresenter from "../../base/BaseListPresenter";

class ConfigurationPresenter extends BaseListPresenter {
    constructor(
        view,
        findObjectUseCase,
        countObjectUseCase,
        deleteObjectUseCase,
        upsertUseCase,
    ) {
        super(view, findObjectUseCase, countObjectUseCase, deleteObjectUseCase);
        this.upsertUseCase = upsertUseCase;
        this.view = view;
    }

    init() {
        this.limit = 20;
        this.where = {};
        this.search = {};
        this.filter = {};
        this.filterDate = {};
        this.filterList = {};
        this.include = ["all"];
        this.keys = [];
        this.sort = { createdAt: -1 };
        this.progress = true;
        this.reset();
    }

    createQuery() {
        const skip = (this.current - 1) * this.limit;
        const query = {
            limit: this.limit,
            skip: skip,
            where: {
                ...this.where,
                ...this.search,
                ...this.filter,
                ...this.filterDate,
                ...this.filterList,
            },

            include: this.include,
        };
        if (this.sort) {
            query.sort = this.sort;
        }
        const keys = this.keys || this.view.getKeys() || [];
        if (keys.length > 0) {
            query.keys = keys;
        }
        return query;
    }


    async findObjects() {
        const user = this.view.getCurrentUser();
        const collection = this.view.getCollectionName();
        let query;

        query = this.createQuery();

        try {
            this.showProgress();
            this.findObjectUseCase.abort();
            const objects = await this.findObjectUseCase.execute(collection, query);
            this.objects = this.objects.concat(objects);
            this.view.setTotal(this.objects.length);
            this.view.setObjects(this.objects);
            this.hideProgress();
        } catch (error) {
            this.hideProgress();
            this.view.showError(error);
        }
        this.progress = false;
    }


    onChangeObject(objects) {
        this.change = objects;
        this.object = objects;
    }
}

export default ConfigurationPresenter;
