// function objectToOption(object, indexes) {
//   // first filter the index has value then if no value the id will be use
//   return {
//     label:
//       indexes
//         .filter((i) => object[i])
//         .map((i) => object[i])
//         .join(" ") || object.id,
//     value: object.id,
//     object: object,
//   };
// }

// export default objectToOption;

function objectToOption(object, indexes) {
  // Filter indexes to only include "agentName"
  const filteredIndexes = indexes.filter((index) => index === "agentName");

  return {
    label:
      filteredIndexes
        .filter((i) => object[i])
        .map((i) => object[i])
        .join(" ") || object.id,
    value: object.id,
    object: object,
  };
}

export default objectToOption;
