// import React from "react";
// import classNames from "../../classNames";

// const noop = () => {};
// const defaultProps = {
//   type: "text",
//   strict: false,
//   onChange: noop,
// };

// function InputCurrency({
//   className,
//   type,
//   strict,
//   onChange,
//   uppercase,
//   ...props
// }) {
//   // Function to format numbers with commas
//   const formatNumber = (value) => {
//     return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//   };

//   function onInput(e) {
//     let value = e.target.value;

//     // Handling for number type
//     if ("number" === "number") {
//       // Strip non-numeric characters except for the decimal point
//       value = value.replace(/[^\d.]/g, "");
//       value = formatNumber(value);
//     } else {
//       // Handling for string input
//       const character = value.charAt(value.length - 1);
//       if (strict && !/^[a-zA-Z]*$/.test(character)) {
//         return;
//       }
//     }

//     if (uppercase) {
//       value = value.toUpperCase();
//     }

//     e.target.value = value;
//     onChange(value);
//   }

//   return (
//     <div>
//       <input
//         className={classNames("form-control", className)}
//         onInput={onInput}
//         {...props}
//       />
//       <br />
//     </div>
//   );
// }
// InputCurrency.defaultProps = defaultProps;
// export default InputCurrency;

import React from "react";
import classNames from "../../classNames";

const noop = () => {};
const defaultProps = {
  type: "text",
  strict: false,
  onChange: noop,
};

function InputCurrency({
  className,
  type,
  strict,
  onChange,
  uppercase,
  ...props
}) {
  const formatNumber = (value) => {
    // Split the number into integer and decimal parts
    const [integerPart, decimalPart] = value.split(".");

    // Format the integer part with commas
    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );

    // Return the formatted number
    return decimalPart !== undefined
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart;
  };

  function onInput(e) {
    let value = e.target.value;

    // Strip non-numeric characters except for the decimal point
    value = value.replace(/[^\d.]/g, "");
    value = formatNumber(value);

    if (uppercase) {
      value = value.toUpperCase();
    }

    // Manually update the input value
    e.target.value = value;
    onChange(value);
  }

  return (
    <div>
      <input
        className={classNames("form-control", className)}
        onInput={onInput}
        {...props}
      />
      <br />
    </div>
  );
}
InputCurrency.defaultProps = defaultProps;
export default InputCurrency;
