export default function getMenu(menu, role) {
  const newMenu = menu
    .map((item) => {
      if (item.access?.includes(role) || item.access?.includes("*")) {
        if (!Array.isArray(item.route)) {
          return { ...item };
        }
        const filteredRoutes = item.route.filter(
          (r) => r.access?.includes(role) || r.access?.includes("*")
        );
        return { ...item, route: filteredRoutes };
      }
      return null;
    })
    .filter((item) => item !== null);

  return newMenu;
}
