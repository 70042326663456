import React from "react";
import MotorCarListPresenter from "./MotorCarListPresenter";
import withRouter from "../../../withRouter";
import NavBar from "../../../components/navbar";
import BaseListPage from "../../../base/BaseListPage";
import InputFactory from "../../../components/InputFactory";
import Table from "../../../components/Table";
import {
  countObjectUseCase,
  deleteObjectUseCase,
  findObjectUseCase,
  upsertUseCase,
} from "../../../usecases/object";
import { exportCSVUseCase } from "../../../usecases/csv/usecases";
import browseFile from "../../../browseFile";
import { InfiniteScroll, Button, Progress, dialog } from "nq-component";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import AddField from "../components/AddField";
import { updateSchemaUseCase } from "../../../usecases/schema/usecases";
import SelectSearch from "../../../components/SelectSearch";
import Select from "react-select";

class MotorCarListPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new MotorCarListPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase(),
      upsertUseCase(),
      deleteObjectUseCase(),
      // exportCSVUseCase(),
      updateSchemaUseCase()
    );
    this.state = {
      isOffcanvasOpen: false,
      objects: [],
      selected: [],
      objectFilter: [],
      carCompanyMake: [],
      makeVariant: [],
      vehicleType: [],
      importShow: false,
    };
  }
  componentDidMount() {
    this.presenter.componentDidMount();
    this.filterList();
    this.findAllFilter();
    this.findVehicle();
  }
  filterList() {
    const user = this.getCurrentUser();
    const role = this.getCurrentRoles();
    if (role.some((role) => role.name === "admin")) {
      this.presenter.filterListing({});
    } else {
      this.presenter.filterListing({});
    }
  }
  getCollectionName() {
    return "motor_cars";
  }

  async findVehicle() {
    const collection = "vehicle_type";
    try {
      const response = await findObjectUseCase().execute(collection);
      this.setState({ vehicleType: response });
    } catch (error) {}
  }

  onChangeObject(objects, index) {
    this.presenter.onChangeObject(objects, index);
  }
  onClickAdd() {
    this.presenter.onClickAdd();
  }

  onClickItem(index, field) {
    this.presenter.onClickItem(index, field);
  }

  importDelete() {
    if (this.state.objectFilter.length > 0) {
      this.presenter.onClickDeleteSelected();

      // this.presenter.onClickImportSendDB([data]);
    } else {
      this.onClickImport(); // Send the transformed data
    }
  }

  onClickImport() {
    browseFile("*").then((files) => {
      if (files.length > 0) {
        const file = files[0];
        console.log("file :", file);
        this.readXLSXFile(file);
      }
    });
  }

  readXLSXFile(file) {
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const csv = XLSX.utils.sheet_to_csv(worksheet);
      this.parseCSVData(csv);
    };
    reader.readAsArrayBuffer(file);
  }

  parseCSVData(csv) {
    Papa.parse(csv, {
      header: true,
      complete: (results) => {
        console.log("Parsed results:", results);

        // Filter out empty rows
        const filteredData = results.data.filter((row) => row["Car Company"]);

        const roleIdMapping = {
          "SUV/AUV/WAGON/PICK-UP/VAN": "778d08f3-8327-4bfc-9599-f0a1737c5db8",
          SEDAN: "52b4626b-8e49-4476-b98c-1e5d122845c1",
        };

        // Map the filtered data to the desired format
        const data = filteredData.map((row) => {
          const vehicleTypeName = row["Vehicle_Type"];
          const vehicleTypeId = roleIdMapping[vehicleTypeName] || "UNKNOWN_ID"; // Default value if not found

          // Dynamically construct the year properties
          const yearData = Object.keys(row).reduce((acc, key) => {
            if (/^\d{4}$/.test(key)) {
              acc[key] = row[key];
            }
            return acc;
          }, {});

          return {
            ...yearData,
            // id: row["id"],
            carCompany: row["Car Company"],
            make: row["Make"],
            carVariant: row["Car Variant"],
            capacity: Number(row["Capacity"]),
            status: row["status"],
            acl: {
              read: ["*"],
              write: ["*"],
            },
            vehicleType: {
              name: vehicleTypeName,
              acl: {
                read: [
                  "*",
                  "034a6616-544c-4a93-aba9-ad1e2d59db99",
                  "role:admin",
                ],
                write: [
                  "*",
                  "034a6616-544c-4a93-aba9-ad1e2d59db99",
                  "role:admin",
                ],
              },
              id: vehicleTypeId,
            },
          };
        });

        console.log("import:", data);
        this.presenter.onClickImportSendDB([data]);
        // if (this.state.objectFilter.length > 0) {
        //   this.presenter.onClickDeleteSelected();

        //   this.presenter.onClickImportSendDB([data]);
        // } else {
        //    // Send the transformed data
        // }
      },
    });
  }

  showImport() {
    dialog.close();
    // this.reload();
    this.onClickImport();
  }

  getMotorObjects() {
    return this.state.objectFilter;
  }

  async onClickExport() {
    const query = {
      include: ["all"],
    };
    const data = await findObjectUseCase().execute("motor_cars", query);

    // Collect all year keys from the data
    const allYearKeys = new Set();
    data.forEach((row) => {
      Object.keys(row).forEach((key) => {
        if (/^\d{4}$/.test(key)) {
          allYearKeys.add(key);
        }
      });
    });

    // Convert the set to an array and sort it
    const sortedYearKeys = Array.from(allYearKeys).sort();

    const mappedData = data.map((row) => {
      // Construct year data in sorted order
      const yearData = sortedYearKeys.reduce((acc, key) => {
        acc[key] = row[key] || "";
        return acc;
      }, {});

      return {
        ...yearData, // Place year data first
        id: row.id,
        "Car Company": row.carCompany,
        Make: row.make,
        "Car Variant": row.carVariant,
        Capacity: row.capacity,
        status: row.status,
        Vehicle_Type: row.vehicleType.name,
      };
    });

    console.log("Mapped Data:", mappedData); // Debugging line to check mapped data

    const worksheet = XLSX.utils.json_to_sheet(mappedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "MotorCars");

    XLSX.writeFile(workbook, "MotorCars.xlsx");
  }

  onCollapse(index, object) {
    const user = this.getCurrentUser();
    const role = this.getCurrentRoles();
    return (
      <div>
        <div className="d-flex">
          <ul className="list-unstyled ms-1 text-truncate">
            <li>
              <span className="ms-2 fw-light fw-bold">Brand: </span>
              <span className="text-nowrap">{object.carCompany}</span>
            </li>
            {/* <li>
              <span className="ms-2 fw-light fw-bold">Year: </span>
              <span className="text-nowrap">{object.year}</span>
            </li> */}

            <li>
              <span className="ms-2 fw-light fw-bold">Make: </span>
              <span className="text-nowrap">{object.make}</span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">Variant: </span>
              <span className="text-nowrap">{object.carVariant}</span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">Capacity: </span>
              <span className="text-nowrap">{object.capacity}</span>
            </li>
          </ul>
        </div>
        {/* {user.roles.some(
          (role) => role.name === "Special Agent" || role.name === "Admin"
        ) && ( */}
        <button
          onClick={this.onClickItem.bind(this, index)}
          className="btn btn-primary me-2"
          style={{ backgroundColor: "#014900", border: 0 }}
        >
          EDIT
        </button>
        {(object.status === "INACTIVE" || !object.status) && (
          <button
            onClick={() => this.presenter.onClickStatus(index, "ACTIVE")}
            className="btn btn-primary me-2"
            style={{ backgroundColor: "#FDCB5B", border: 0 }}
          >
            SET ACTIVE
          </button>
        )}
        {(object.status === "ACTIVE" || !object.status) && (
          <button
            onClick={() => this.presenter.onClickStatus(index, "INACTIVE")}
            className="btn btn-primary me-2"
            style={{ backgroundColor: "#D4A12D", border: 0 }}
          >
            SET INACTIVE
          </button>
        )}
        {/* )} */}
      </div>
    );
  }
  toggleOffcanvas = () => {
    this.setState((prevState) => ({
      isOffcanvasOpen: !prevState.isOffcanvasOpen,
    }));
  };

  onClickAddField(schema) {
    const schemas = this.getSchemas();
    console.log("schemas", schemas);
    console.log("schema", schema);
    dialog.fire({
      html: (
        <AddField
          schema={schema}
          collections={schemas.map((s) => s.collection)}
          onSubmit={(s) => this.presenter.onSubmitEditCollection(s)}
          onCancel={() => dialog.close()}
        />
      ),
      footer: false,
    });
  }

  closeDialog() {
    dialog.close();
  }

  getUniqueMakes = (carCompanyMake) => {
    const uniqueMakes = new Set();
    const filteredObjects = carCompanyMake.filter((object) => {
      if (!uniqueMakes.has(object.make)) {
        uniqueMakes.add(object.make);
        return true;
      }
      return false;
    });
    return filteredObjects;
  };

  getUniqueCarCompany = (objectFilter) => {
    const uniqueMakes = new Set();
    const filteredObjects = objectFilter.filter((object) => {
      if (!uniqueMakes.has(object.carCompany)) {
        uniqueMakes.add(object.carCompany);
        return true;
      }
      return false;
    });
    return filteredObjects;
  };

  getUniqueVariantCompany(makeVariant) {
    const uniqueMakes = new Set();
    const filteredObjects = makeVariant.filter((object) => {
      if (!uniqueMakes.has(object.carVariant)) {
        uniqueMakes.add(object.carVariant);
        return true;
      }
      return false;
    });
    return filteredObjects;
  }

  async findAllFilter() {
    const objectFilter = await findObjectUseCase().execute("motor_cars");
    this.setState({ objectFilter });
  }

  async onChangeStatusMake(value) {
    const query = {
      where: {
        make: value?.value,
      },
    };
    try {
      const getMakeVariant = await findObjectUseCase().execute(
        "motor_cars",
        query
      );
      console.log("getMakeVariant : ", getMakeVariant);
      this.setState({ makeVariant: getMakeVariant });
    } catch (error) {
      console.log("Error getting car company make : ", error);
      return;
    }

    this.setState({ status: value });
    this.presenter.onChangeStatusMake(value);
  }

  async onChangeStatusCompany(value) {
    console.log("Company Value : ", value);
    const query = {
      where: {
        carCompany: value?.value,
      },
    };
    try {
      const getCarCompanyMake = await findObjectUseCase().execute(
        "motor_cars",
        query
      );
      console.log("getCarCompanyMake : ", getCarCompanyMake);
      this.setState({ carCompanyMake: getCarCompanyMake });
    } catch (error) {
      console.log("Error getting car company make : ", error);
      return;
    }
    this.setState({ status: value });
    this.presenter.onChangeStatusCompany(value);
  }

  onChangeStatusVariant(value) {
    this.setState({ status: value });
    this.presenter.onChangeStatusVariant(value);
  }

  onChangeVehicle(value) {
    this.setState({ status: value });
    this.presenter.onChangeVehicle(value);
  }

  onChangeStatus(value) {
    this.setState({ status: value });
    this.presenter.onChangeStatus(value);
  }

  onChangeFilter(type, value, field) {
    console.log("t", type, "v", value, "f", field);

    const where = {};
    if (!value) {
      // If value is null or undefined (clear event), reset the filter
      this.filterSubmit(where);
    } else {
      switch (type) {
        case "Pointer":
          if (Object?.keys(value)?.length > 0) {
            where[field] = {
              id: value.value, // The 'id' should come from 'value'
            };
          }
          break;
        case "Boolean":
          where[field] = value;
          break;
        default:
          where[field] = { $regex: value, $options: "i" };
      }
    }
    this.filterSubmit(where);
  }

  render() {
    const { isOffcanvasOpen } = this.state;
    const schema = this.getSchema(this.getCollectionName());
    console.log("render schema", schema);
    const {
      objects,
      selected,
      total,
      count,
      progress,
      objectFilter,
      carCompanyMake,
      makeVariant,
    } = this.state;
    const uniqueMakeObjects = this.getUniqueMakes(carCompanyMake);
    const uniqueCarObjects = this.getUniqueCarCompany(objectFilter);
    const uniqueVariantObjects = this.getUniqueVariantCompany(makeVariant);
    console.log("OF", objectFilter);
    console.log("motor : ", objects);
    const user = this.getCurrentUser();
    const role = this.getCurrentRoles();

    if (!schema) return <Progress />;
    return (
      <>
        <NavBar />
        <div className="overflow-auto">
          <InfiniteScroll
            className="h-100"
            loadMore={this.loadMore.bind(this)}
            hasMore={!progress && count > objects.length}
          >
            <div className="p-3 p-lg-4">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="fw-bold mt-3 text-capitalize">Motor Cars</h1>
                {selected.length > 0 ? (
                  <div>
                    {/* <span className="ms-2">Selected: </span>
                    <span className="fs-sm text-nowrap">{selected.length}</span>
                    <span className="ms-1">of </span>
                    <span className="ms-1">Count </span>
                    <span className="fs-sm text-nowrap">
                      {this.objectFilter?.length}
                    </span> */}
                  </div>
                ) : (
                  <div>
                    {/* <span className="ms-2">Total: </span>
                    <span className="fs-sm text-nowrap">{objects.length}</span>
                    <span className="ms-1">of </span> */}
                    <span className="ms-1">Total: </span>
                    <span className="fs-sm text-nowrap">{count}</span>
                  </div>
                )}

                <div className="d-block d-md-none mt-2">
                  <div className="d-flex align-items-center ">
                    <i
                      className="bi bi-filter"
                      style={{ fontSize: "25px", color: "#014900" }}
                      onClick={this.toggleOffcanvas}
                      aria-controls="offcanvasRight"
                    ></i>
                  </div>

                  <div
                    className={`offcanvas offcanvas-end ${
                      isOffcanvasOpen ? "show" : ""
                    }`}
                    tabIndex="-1"
                    id="offcanvasRight"
                    aria-labelledby="offcanvasRightLabel"
                    style={{
                      visibility: isOffcanvasOpen ? "visible" : "hidden",
                    }}
                  >
                    <div
                      className="offcanvas-header"
                      style={{ backgroundColor: "#014900" }}
                    >
                      <h5
                        className="offcanvas-title text-white"
                        id="offcanvasRightLabel"
                      >
                        Filters
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={this.toggleOffcanvas}
                      ></button>
                    </div>
                    <div className="offcanvas-body">
                      <div className=" mt-3">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isClearable={true}
                          isSearchable={true}
                          name="brand"
                          options={uniqueMakeObjects.map((item) => ({
                            label: item.make,
                            value: item.make,
                          }))}
                          onChange={(value) => this.onChangeStatusMake(value)}
                          placeholder="Select makes"
                        />

                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isClearable={true}
                          isSearchable={true}
                          name="brand"
                          options={uniqueCarObjects.map((item) => ({
                            label: item.carCompany,
                            value: item.carCompany,
                          }))}
                          onChange={(value) =>
                            this.onChangeStatusCompany(value)
                          }
                          placeholder="Select Brand"
                        />
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isClearable={true}
                          isSearchable={true}
                          name="brand"
                          options={uniqueVariantObjects.map((item) => ({
                            label: item.carVariant,
                            value: item.carVariant,
                          }))}
                          onChange={(value) =>
                            this.onChangeStatusVariant(value)
                          }
                          placeholder="Select Variant"
                        />
                        {/* {Object.keys(schema.filters || {}).map((field) => {
                          let { type, ...options } = schema.filters[field];

                          return (
                            <div className="mb-2">
                              <InputFactory
                                key={field}
                                className="ms-0"
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter.bind(this, type)}
                                {...options}
                              />
                            </div>
                          );
                        })} */}
                        <Select
                          className="basic-single "
                          classNamePrefix="select"
                          isClearable={true}
                          isSearchable={true}
                          name="brand"
                          options={this.state.vehicleType?.map((item) => ({
                            label: item.name,
                            value: item.id,
                          }))}
                          // onChange={(value) => this.onChangeVehicle(value)}
                          onChange={(value) =>
                            this.onChangeFilter("Pointer", value, "vehicleType")
                          }
                          placeholder="Select Vehicle Type"
                        />

                        {/* <DateRange
                          onChange={this.onChangeDate.bind(this)}
                          field="date"
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-md-flex d-none mt-3 justify-content-between align-items-center ">
                <div className="row">
                  <div className="col-3">
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable={true}
                      isSearchable={true}
                      name="brand"
                      options={uniqueCarObjects
                        .map((item) => ({
                          label: item.carCompany,
                          value: item.carCompany,
                        }))
                        .sort((a, b) => a.label.localeCompare(b.label))}
                      onChange={(value) => this.onChangeStatusCompany(value)}
                      placeholder="Select Brand"
                    />
                  </div>
                  <Select
                    className="basic-single col-3"
                    classNamePrefix="select"
                    isClearable={true}
                    isSearchable={true}
                    name="brand"
                    options={uniqueMakeObjects
                      .map((item) => ({
                        label: item.make,
                        value: item.make,
                      }))
                      .sort((a, b) => a.label.localeCompare(b.label))}
                    onChange={(value) => this.onChangeStatusMake(value)}
                    placeholder="Select Make"
                  />

                  <Select
                    className="basic-single col-3"
                    classNamePrefix="select"
                    isClearable={true}
                    isSearchable={true}
                    name="brand"
                    options={uniqueVariantObjects
                      .map((item) => ({
                        label: item.carVariant,
                        value: item.carVariant,
                      }))
                      .sort((a, b) => a.label.localeCompare(b.label))}
                    onChange={(value) => this.onChangeStatusVariant(value)}
                    placeholder="Select Variant"
                  />
                  {/* {Object.keys(schema.filters || {}).map((field) => {
                    let { type, ...options } = schema.filters[field];

                    return (
                      <div className="col-3">
                        <InputFactory
                          key={field}
                          className="ms-0 col-4"
                          type={type}
                          field={field}
                          where={{}}
                          onChange={this.onChangeFilter.bind(this, type)}
                          {...options}
                        />
                      </div>
                    );
                  })} */}
                  <Select
                    className="basic-single col-3"
                    classNamePrefix="select"
                    isClearable={true}
                    isSearchable={true}
                    name="brand"
                    options={this.state.vehicleType?.map((item) => ({
                      label: item.name,
                      value: item.id,
                    }))}
                    // onChange={(value) => this.onChangeVehicle(value)}
                    onChange={(value) =>
                      this.onChangeFilter("Pointer", value, "vehicleType")
                    }
                    placeholder="Select Vehicle Type"
                  />

                  <Select
                    className="basic-single col-3 mt-2"
                    classNamePrefix="select"
                    isClearable={true}
                    isSearchable={true}
                    name="brand"
                    options={["ACTIVE", "INACTIVE"].map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    onChange={(value) => this.onChangeStatus(value)}
                    placeholder="Select Status"
                  />
                  <div className="col-4 mt-2">
                    <button
                      className="btn btn-success text-white me-2"
                      aria-expanded="false"
                      onClick={this.importDelete.bind(this)}
                    >
                      <i className="bi bi-arrow-bar-down"></i> IMPORT
                    </button>
                    <button
                      className="btn btn-success text-white"
                      aria-expanded="false"
                      onClick={this.onClickAddField.bind(this, schema)}
                    >
                      <i className="bi bi-plus"></i> ADD YEAR
                    </button>
                  </div>
                  <div className="col-2 mt-2"></div>
                  <div className="col-3 mt-2 text-end">
                    {" "}
                    {this.state.selected.length > 0 && (
                      <>
                        <button
                          className="btn btn-danger"
                          aria-expanded="false"
                          onClick={this.onClickDeleteSelected.bind(this)}
                        >
                          <i className="bi bi-trash"></i>Delete
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <Table
                fields={schema.fields}
                groups={schema.groups}
                objects={objects}
                collapsable
                selectable
                excludeFields={Object.keys(schema.fields).reduce(
                  (acc, key) => {
                    const options = schema.fields[key];
                    if (options.read === false) {
                      acc.push(key);
                    }
                    switch (options._type || options.type) {
                      case "Relation":
                      case "Array":
                      case "Object":
                      case "File":
                        acc.push(key);
                        break;
                      default:
                    }
                    return acc;
                  },
                  [
                    "acl",
                    "password",
                    "br",
                    "or",
                    "items",
                    "id",
                    "createdAt",
                    // "2017",
                    // "2018",
                    // "2019",
                    // "2020",
                    // "2021",
                    // "2022",
                    // "2023",
                    // "2024",
                    // "make",
                    // "model",
                    "model",
                    "capacity",
                  ]
                )}
                selected={selected}
                onSelect={this.onSelect.bind(this)}
                onSelectAll={this.onSelectAll.bind(this)}
                progress={progress}
                onClickItem={this.onClickItem.bind(this)}
                onCollapse={this.onCollapse.bind(this)}
                className="mt-3"
              />
            </div>
            <div className="position-fixed bottom-0 end-0 m-4">
              <Button
                className="btn shadow-sm"
                onClick={this.onClickAdd.bind(this)}
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "25px",
                  backgroundColor: "#014900",
                }}
              >
                <i className="bi bi-plus text-white" />
              </Button>
            </div>
          </InfiniteScroll>
        </div>
      </>
    );
  }
}

export default withRouter(MotorCarListPage);
