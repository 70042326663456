import React, { useEffect, useRef, useState } from 'react';
import printJS from 'print-js';
import './index.css';
import UpsertUseCase from "../../../usecases/object/UpsertUseCase"
import html2pdf from 'html2pdf.js';
// import logo from "/profile-logo.jpg"

const ClientInformation = ({ handleBack, fvm, commisionRate, roadAssist, basicPrem, totalGrossPrem, model }) => {
	const [clientInfo, setClientInfo] = useState({
		name: "",
		email: "",
		contact: "",
		file: "",
		ref_no: ""
	});

	// const method = "POST";
	// const path = "/send-email/56c1095c-1447-4e16-8ae7-2a3d795ea951";
	// const obj = {
	// 	to: client[0].email,
	// 	body: this.change.url,
	// 	subject: "Reports Done FFCC",
	// };

	// const sendEmail = async () => {
	// 	try {
	// 		await restUseCase().execute(method, path, {
	// 			body: obj,
	// 		})
	// 	} catch (e) {
	// 		console.log(e)
	// 	}
	// }

	const [error, setError] = useState(false)
	const [shouldInsert, setShouldInsert] = useState(false);
	const submitData = () => {
		const { name, contact, email, file } = clientInfo;
		if (!name || !contact || !email || !file) {
			setError(true)
			return;
		}
		document.getElementById('customModal').style.display = 'block';
		const ref = generateUniqueReferences()

		console.log("ref: " + ref)
		setClientInfo(prev => ({ ...prev, ref_no: ref }), console.log(clientInfo))
		setError(false)
		download()
		setShouldInsert(true);
	}

	useEffect(() => {
		if (shouldInsert) {
			const insert = new UpsertUseCase();
			insert.execute("client_info", clientInfo);

			const name = clientInfo.name;
			const data = {
				customerName: name,
				status: "sent"
			}

			const tran = {
				customer: name,
				amount: totalGrossPrem,
				model: model
			}

			insert.execute("quotations", data)
			insert.execute("transactions", tran)
			setShouldInsert(false);
		}
	}, [clientInfo, shouldInsert]);


	async function download() {
		const filename = 'customer.pdf';
		const content = document.getElementById('container.pdf');

		content.classList.remove("d-none");

		try {
			// Make the element temporarily visible
			content.style.display = 'block';

			const opt = {
				margin: 1.5,
				filename: filename,
				image: { type: 'jpeg', quality: 0.98 },
				html2canvas: { scale: 2 },
				jsPDF: {
					unit: 'cm', format: 'letter',
					orientation: 'portrait'
				}
			};

			await html2pdf().set(opt).from(content).save();
			// Hide the element again
			content.classList.add("d-none");
			document.getElementById('customModal').style.display = 'none'

		} catch (error) {
			console.error('Error:', error.message);
		}
	}

	// Function to generate a list of unique reference numbers
	const generateUniqueReferences = () => {
		let prefix = 'MICI-';

		const generate_random = () => Math.floor(Math.random() * 999999999999).toString().padStart(12, '0');
		const _getMilliseconds = new Date().getMilliseconds().toString().padStart(3, '0');

		// Generate the random number string
		const randomString = generate_random();

		// Ensure that the combined length is 12 characters
		const uniqueReference = randomString.substring(0, 9) + _getMilliseconds;

		// Combine the prefix with the unique reference number
		prefix += uniqueReference;

		return prefix;
	};


	const data = [
		{ coverage: 'Own Damage', sumInsured: fvm, amount_wo_aon: commisionRate, amount_w_aon: commisionRate },
		{ coverage: 'Theft', sumInsured: fvm, amount_wo_aon: '-', amount_w_aon: commisionRate },
		{ coverage: 'Bodily Injury', sumInsured: '200,000', amount_wo_aon: '450', amount_w_aon: commisionRate },
		{ coverage: 'Property Damage', sumInsured: '200,000', amount_wo_aon: '450', amount_w_aon: commisionRate },
		{ coverage: 'Auto PA', sumInsured: '250,000', amount_wo_aon: '1,245', amount_w_aon: commisionRate },
		{ coverage: 'Acts of Nature (AON)', sumInsured: fvm, amount_wo_aon: '5,900', amount_w_aon: commisionRate },
		{ coverage: '', sumInsured: '━━━━━━━━━', amount_wo_aon: '━━━━━━━━━', amount_w_aon: "━━━━━━━━━" },
		{ coverage: '', sumInsured: 'Basic Premium', amount_wo_aon: basicPrem, amount_w_aon: commisionRate },
		{ coverage: '', sumInsured: 'Doc Stamps', amount_wo_aon: '2,495.38', amount_w_aon: commisionRate },
		{ coverage: '', sumInsured: 'E-VAT', amount_wo_aon: '2,395.56', amount_w_aon: commisionRate },
		{ coverage: '', sumInsured: 'LGT', amount_wo_aon: '19.96', amount_w_aon: commisionRate },
		{ coverage: '', sumInsured: '24/7 Roadside Assistance', amount_wo_aon: roadAssist ? '300' : '0', amount_w_aon: commisionRate },
	];

	return (
		<div className='position-relative'>
			<div className='d-flex align-items-center gap-2'>
				<span className='bi-arrow-left cursor-pointer' onClick={handleBack}></span>
				<h1 className='fw-semibold fs-3'>Client Information</h1>
			</div>

			<div class="custom-modal" id="customModal">
				<div class="custom-modal-dialog">
					<div class="custom-modal-content">
						<div class="modal-header">
							<h5 class="modal-title">Send or Download</h5>
							<button onClick={() => document.getElementById('customModal').style.display = 'none'} type="button" class="close" id="closeModalBtn">&times;</button>
						</div>
						<div class="modal-body">
							<p>Select an option.</p>
						</div>
						<div class="modal-footer d-flex align-items-center gap-2">
							<button type="button" class="btn btn-light" id="closeModalBtnFooter">Send</button>
							<button type="button" class="btn btn-primary" id="closeModalBtnFooter" onClick={submitData}>Download</button>
						</div>
					</div>
				</div>
			</div>

			<form className='bg-white p-4 d-flex flex-column gap-3'>
				<p className='text-danger fw-bold'>{error ? "Fill up all required fields" : ""}</p>
				<div>
					<label>Name:</label>
					<input required name='name' type='text' placeholder='Enter name' className='form-control' onChange={(e) => setClientInfo(prev => ({ ...prev, name: e.target.value }))} />
				</div>

				<div>
					<label>Email Address:</label>
					<input required name='email' type='email' placeholder='Enter email' className='form-control' onChange={(e) => setClientInfo(prev => ({ ...prev, email: e.target.value }))} />
				</div>

				<div>
					<label>Mobile number:</label>
					<input required name='contact' type='text' maxLength={11} placeholder='Enter mobile number' className='form-control' onChange={(e) => setClientInfo(prev => ({ ...prev, contact: e.target.value }))} />
				</div>

				<div>
					<label>File Attachment:</label>
					<input required name='file' type='file' className='form-control' onChange={(e) => setClientInfo(prev => ({ ...prev, file: JSON.stringify(e.target.files) }))} />
				</div>

				<div className='d-flex align-items-center gap-3'>
					<button className='btn bg-light' type='button' onClick={submitData}>Save</button>
					{/* <a className='btn bg-light' href='#' onClick={download}>Download</a> */}
					<button className='btn text-light' id="openModalBtn" style={{ background: "#1A8326" }} type='button' onClick={(e) => {
						e.preventDefault();
						const { name, contact, email, file } = clientInfo;
						if (!name || !contact || !email || !file) {
							setError(true)
							return;
						}
						setError(false)
						document.getElementById('customModal').style.display = 'block';
					}}>Send</button>
				</div>
			</form>

			<div id='container.pdf' className='d-none' style={{ position: "relative", minHeight: "920px" }}>
				<img src="/mercantile-logo.png" width={220} />

				<div style={{ marginLeft: '370px', top: "2px", position: "absolute" }}>
					<p style={{ fontSize: ".5rem", fontWeight: "bold", lineHeight: ".4" }}>Mercantile Insurance Building, General Luna St. corner Beaterio St.</p>
					<p style={{ fontSize: ".5rem", fontWeight: "bold", lineHeight: ".4" }}>Intramuros, Manila, Philippines</p>
					<p style={{ fontSize: ".5rem", fontWeight: "bold", lineHeight: ".4" }}>(632) 8527-7701 to 20 • gen_info@mic.com.ph • www.mercantile.ph</p>
				</div>

				<div style={{ marginLeft: '400px', top: "80px", position: "absolute" }}>
					<p style={{ fontSize: ".7rem", fontWeight: "bold", lineHeight: ".4" }}>MOTORCAR INSURANCE PROPOSAL</p>
					<p style={{ fontSize: ".7rem", fontWeight: "bold", lineHeight: ".4" }}>February 28, 2024</p>
				</div>


				<div style={{ position: "absolute", top: "130px", maxWidth: "620px" }}>
					<p style={{ fontSize: ".8rem" }} > Thank you for giving THE MERCANTILE INSURANCE CO., INC., an opportunity to provide you with a proposal
						for your car insurance requirements.</p>
				</div>

				<div style={{ position: "absolute", top: "180px", maxWidth: "620px" }}>
					<p style={{ fontSize: ".8rem" }}>Below are the pertinent information you will need in evaluating our quotation</p>
				</div>

				<div style={{ position: "absolute", top: "220px", maxWidth: "620px" }}>
					<p style={{ fontSize: ".8rem", lineHeight: ".4" }}>ASSURED &#160; &#160; &#160; &#160; &#160; &#160; &#160; &#160; &#160; &#160; <span style={{ fontSize: ".8rem", fontWeight: "bold" }}>{clientInfo.name}</span></p>
					<p style={{ fontSize: ".8rem", lineHeight: ".4" }}>REFERENCE NO &#160; &#160; &#160; &#160; <span style={{ fontSize: ".8rem", fontWeight: "bold" }}>{clientInfo.ref_no}</span></p>
				</div>

				<div style={{ position: "absolute", top: "270px", maxWidth: "620px" }}>
					<p style={{ fontSize: ".8rem", lineHeight: ".6" }}>DESCRIPTIONS  &#160; &#160; &#160; &#160; &#160; <span style={{ fontSize: ".8rem", fontWeight: "bold", lineHeight: "1" }}>{model}</span></p>
				</div>

				<table style={{ position: "absolute", top: "300px", }}>
					<tr className='head' style={{ backgroundColor: "#1A8326" }} >
						<th style={{ color: "white", fontSize: ".8rem", textAlign: "left" }}>COVERAGE</th>
						<th style={{ color: "white", fontSize: ".8rem", textAlign: "center" }}>Sum Insured</th>
						<th style={{ color: "white", fontSize: ".8rem", textAlign: "center" }}>Comprehensive Without AON</th>
						<th style={{ color: "white", fontSize: ".8rem", textAlign: "center" }}>Comprehensive With AON</th>
					</tr>

					{
						data.map((row, index) => (
							<tr key={index}>
								<td style={{ textAlign: "left" }}>{row.coverage}</td>
								<td style={{ textAlign: "right" }}>{row.sumInsured}</td>
								<td style={{ textAlign: "right" }}>{row.amount_wo_aon}</td>
								<td style={{ textAlign: "right" }}>{row.amount_w_aon}</td>
							</tr>
						))
					}

					<tr style={{ backgroundColor: "#1A8326" }}>
						<td ></td>
						<td style={{ textAlign: "center", color: "white", fontWeight: "bold", fontSize: "12px" }}>TOTAL GROSS PREMIUM</td>
						<td style={{ textAlign: "right", color: "white" }}>{totalGrossPrem}</td>
						<td style={{ textAlign: "right", color: "white" }}>{totalGrossPrem}</td>
					</tr>
				</table>

				<div style={{ position: "absolute", top: "670px", maxWidth: "620px" }}>
					<p style={{ fontSize: ".8rem", lineHeight: .7 }}>AGENTS CODE:  &#160;  &#160;  &#160;   &#160;  &#160;  &#160; &#160; &#160; &#160; &#160; &#160; &#160; &#160; &#160; &#160; &#160; &#160; <span style={{ fontSize: ".8rem", fontWeight: "bold" }}>1001</span></p>
					<p style={{ fontSize: ".8rem", lineHeight: .7 }}>DEDUCTIBLE/PARTICIPATION:  &#160; &#160; &#160; &#160; &#160; <span style={{ fontSize: ".8rem", fontWeight: "bold", lineHeight: "1" }}>0.50% of Sum Insured minimum of Php 2,000.00 whichever is higher</span></p>
				</div>

				<div style={{ position: "absolute", top: "730px", maxWidth: "620px" }}>
					<p style={{ fontSize: ".8rem", lineHeight: 1 }}>CONDITIONS:</p>
				</div>

				<p style={{ position: "absolute", top: "730px", left: "40px", lineHeight: .7, fontSize: ".8rem", marginLeft: "105px", fontWeight: "bold" }}>Subject to the standard insuring provisions of the Mercantile Insurance Co.,</p>
				<p style={{ position: "absolute", top: "750px", left: "40px", lineHeight: .7, fontSize: ".8rem", marginLeft: "105px", fontWeight: "bold" }}>Inc.'s Motorcar Insurance Policy including the usual warranties, clauses and</p>
				<p style={{ position: "absolute", top: "770px", left: "40px", lineHeight: .7, fontSize: ".8rem", marginLeft: "105px", fontWeight: "bold" }}>other conditions applicable</p>

				<div style={{ position: "absolute", top: "780px", maxWidth: "620px" }}>
					<p style={{ fontSize: ".7rem", lineHeight: ".7" }}>Note:</p>
					<p style={{ fontSize: ".7rem", lineHeight: ".7" }}>Warranted no loss as of February 28, 2024</p>
				</div>

				<div style={{ position: "absolute", top: "830px", maxWidth: "620px" }}>
					<p style={{ fontSize: ".7rem", lineHeight: ".7" }}>Should you have any further inquiries regarding our proposal, please do not hesitate to inform us immediately.
					</p>
					<p style={{ fontSize: ".7rem", lineHeight: ".7" }}>We are looking forward to receiving your favorable response.
					</p>
					<p style={{ fontSize: ".7rem", lineHeight: ".7" }}>Thank you.
					</p>
				</div>

				<div style={{ position: "absolute", top: "900px", maxWidth: "620px" }}>
					<p style={{ fontSize: ".8rem", lineHeight: 1, fontWeight: "bold" }}>THE MERCANTILE INSURANCE</p>
				</div>
			</div >
		</div >
	);
};

export default ClientInformation;
