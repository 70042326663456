import React from "react";
import withRouter from "../../../withRouter";
import QuatationFormPresenter from "./QuatationFormPresenter";
import {
  findObjectUseCase,
  getObjectUseCase,
  upsertUseCase,
} from "../../../usecases/object";
import NavBar from "../../../components/navbar";
import FindObjectUseCase from "../../../usecases/object/FindObjectUseCase";
import BaseFormPage from "../../../base/BaseFormPage";
import Progress from "../../../components/Progress";
import InputFactory from "../../../components/InputFactory";

class QuatationFormPage extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = {
      object: {},
      advanced: false,
      sendData: {
        year: "",
        brand: "",
        model: "",
        variant: "",
        carVariant: "",
        roadAssist: false,
        commissionRate: "",
        vehicleType: "",
      },

      id: "",

      fvm: "",
      carCompanyList: [],
      carModelList: [],
      carVariantList: [],
      premiumRateList: [],
      isComputation: false,
      isReset: false,
      vehicleTypeList: [],
      vehicleTypeListVal: [],
      displayVehicleType: "",
      pool: [],
    };
    this.handleBackComputer = this.handleBackComputer.bind(this);
    this.presenter = new QuatationFormPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase(),
      findObjectUseCase()
    );
  }

  getCollectionName() {
    return "motor_cars";
  }

  onClickAdvance() {
    this.setState({ advanced: !this.state.advanced });
  }

  async componentDidMount() {
    const get = new FindObjectUseCase();
    const motorCar = get.execute("motor_cars");
    console.log("mc", motorCar);
    const res = await get.execute("standard_odta");
    const vehicleTypes = await get.execute("vehicle_type");
    const DEDUCTIBLE = await get.execute("deductible_module");
    const val = DEDUCTIBLE.map((item) => item.deductible);

    const premiums = res.map(
      (r) => `${r.premiumRateAON} / ${r.commissionRateODTON}%`
    );

    this.setState({
      premiumRateList: premiums,
      vehicleTypeList: vehicleTypes,
      vehicleTypeListVal: val,
    });

    const id = this.getParams().id;
    if (id) {
      const quote = await get.execute("quotations", { where: { id: id } });

      if (quote) {
        this.setState((prevState) => ({
          id: id,
          sendData: {
            ...prevState.sendData,
            year: quote[0]?.year,
            brand: quote[0]?.brand,
            variant: quote[0]?.variant,
            model: quote[0]?.model,
            carVariant: quote[0]?.carVariant,
            commissionRate: quote[0]?.commissionRate,
            roadAssist: quote[0]?.roadAssist,
            vehicleType: quote[0]?.vehicleType,
          },
          fvm: quote[0]?.fvm,
        }));
      }
    }
  }

  getAcl() {
    const user = this.getCurrentUser();
    return {
      read: ["*", user.id, "role:admin"],
      write: [user.id, "role:admin"],
    };
  }

  async getBrand(year) {
    const get = new FindObjectUseCase();
    const query = {
      where: {},
    };
    query.where[year] = { $ne: [0] }; // Use $nin to exclude 0 and empty strings
    const res = await get.execute("motor_cars", query);

    // Filter again in case the database query did not filter out all the values
    const filtered = res.filter(
      (item) => item[year] !== 0 && item[year] !== "" && item[year]
    );

    const carCompanySet = new Set(filtered.map((car) => car.carCompany));
    const _carCompanyList = Array.from(carCompanySet); // Converting the set back to an array

    this.setState({ carCompanyList: _carCompanyList, pool: filtered });
  }

  async getModel(brand) {
    console.log("pool", this.state.pool);
    const aModelList = this.state.pool.filter(
      (item) => item.carCompany == brand
    );

    const carModelSet = new Set(aModelList.map((car) => car.carVariant));
    const _carModelList = Array.from(carModelSet);

    this.setState({ carModelList: _carModelList });
  }

  getVariant(model) {
    const { pool, sendData } = this.state;
    const aVariantList = pool.filter((item) => item.carVariant === model);
    console.log("aV", aVariantList);
    const carVariantSet = new Set(aVariantList.map((car) => car.capacity));
    const _carVariantList = Array.from(carVariantSet);

    let newFvm;
    if (aVariantList.length > 0 && sendData.year) {
      newFvm = aVariantList[0][sendData.year];
    } else {
      console.warn("aVariantList[0] or sendData.year is not defined");
    }

    this.setState({ carVariantList: _carVariantList, fvm: newFvm || "" });
    this.setState((prevState) => ({
      sendData: {
        ...prevState.sendData,
        variant: _carVariantList[0],
      },
    }));
  }

  handleYearChange = (e) => {
    const newYear = !e.target?.value
      ? this.state.sendData.year
      : e.target.value;
    this.setState(
      (prevState) => ({
        sendData: {
          ...prevState.sendData,
          year: newYear,
        },
      }),
      () => {
        this.getBrand(newYear);
      }
    );
  };

  handleModelChange = (e) => {
    const newModel = !e.target?.value
      ? this.state.sendData.model
      : e.target.value;

    const filteredVehicle = newModel.includes("Sedan")
      ? this.state.vehicleTypeListVal[0]
      : this.state.vehicleTypeListVal[1];
    const show = newModel.includes("Sedan")
      ? this.state.vehicleTypeList[0]
      : this.state.vehicleTypeList[1];

    console.log(show);
    console.log(show.name);

    this.setState(
      (prevState) => ({
        sendData: {
          ...prevState.sendData,
          model: newModel,
          vehicleType: filteredVehicle,
        },
        displayVehicleType: show.name,
      }),
      () => {
        this.getVariant(newModel);
      }
    );
  };

  handleBrandChange = (e) => {
    const newBrand = !e.target?.value
      ? this.state.sendData.brand
      : e.target.value;
    this.setState(
      (prevState) => ({
        sendData: {
          ...prevState.sendData,
          brand: newBrand,
        },
      }),
      () => {
        this.getModel(newBrand);
      }
    );
  };

  handleVariantChange = (e) => {
    const variant = this.state.sendData.variant
      ? this.state.sendData.variant
      : this.state.carVariantList[0];
    this.setState((prevState) => ({
      sendData: {
        ...prevState.sendData,
        variant: variant,
      },
    }));
  };

  handleBackComputer = (e) => {
    this.setState({ isComputation: false });
  };

  resetForm() {
    this.setState((prev) => ({
      sendData: {
        ...prev.sendData,
        year: "",
        brand: "",
        model: "",
        variant: "",
        roadAssist: false,
        commissionRate: "",
        vehicleType: "",
      },
      displayVehicleType: "",
      fvm: "",
      isReset: true,
    }));
  }

  render() {
    const schema = this.getSchema(this.getCollectionName());
    const user = this.getCurrentUser();
    console.log("CARvARIANT", this.state.sendData.carVariant);
    if (!schema) return <h1>no schema</h1>;
    const label = this.getObjectId() === undefined ? "Add New " : "Edit ";
    return (
      <>
        <NavBar
          action={() => (
            <div className="dropdown dropstart d-inline-block">
              <i
                role="button"
                data-bs-toggle="dropdown"
                className="bi bi-three-dots-vertical"
              ></i>
              <div className="dropdown-menu fs-xs">
                <button
                  onClick={this.onClickAdvance.bind(this)}
                  className="dropdown-item py-3"
                >
                  <i className="bi bi-gear pe-2" />
                  Toggle Advance
                </button>
              </div>
            </div>
          )}
        />
        <div className="overflow-auto">
          <div className="h-100">
            <div className="p-3 p-lg-4">
              <h1 className="fw-bold mt-3 text-capitalize">
                Create new Quotation
              </h1>
              <div className="mt-3 bg-white shadow rounded p-3 px-lg-5 py-lg-4">
                <form onSubmit={this.onSubmitForm.bind(this)}>
                  <div className="row g-3 py-4">
                    <div>
                      <label>Year</label>
                      <select
                        required
                        className="form-select"
                        value={this.state.sendData.year}
                        name="year"
                        onChange={this.handleYearChange}
                      >
                        <option>Select year</option>
                        <option>2017</option>
                        <option>2018</option>
                        <option>2019</option>
                        <option>2020</option>
                        <option>2021</option>
                        <option>2022</option>
                        <option>2023</option>
                        <option>2024</option>
                      </select>
                    </div>

                    <div>
                      <label>Brand</label>
                      <select
                        required
                        className="form-select"
                        value={this.state.sendData.brand}
                        name="brand"
                        onChange={this.handleBrandChange}
                      >
                        <option>Select brand</option>
                        {this.state.sendData.brand && (
                          <option>{this.state.sendData.brand}</option>
                        )}

                        {this.state.carCompanyList.map((car) => (
                          <option value={car} key={car}>
                            {car}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div>
                      <label>Car Variant</label>
                      <select
                        required
                        className="form-select"
                        value={this.state.sendData.model}
                        name="model"
                        onChange={this.handleModelChange}
                      >
                        <option>Select model</option>
                        {this.state.sendData.carVariant && (
                          <option>
                            {this.state.sendData.carVariant.map((car) => (
                              <option key={car}>{car}</option>
                            ))}
                          </option>
                        )}
                        {this.state.carModelList.map((car) => (
                          <option key={car}>{car}</option>
                        ))}
                      </select>
                    </div>

                    <div>
                      <label>Capacity</label>
                      <input
                        type="text"
                        value={this.state.sendData.variant}
                        className="form-control"
                        name="variant"
                        disabled
                      />
                    </div>

                    <div>
                      <label>Vehicle Type</label>
                      <input
                        type="text"
                        value={this.state.displayVehicleType}
                        className="form-control"
                        disabled
                      />
                    </div>

                    <div className="d-flex gap-2">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={this.state.sendData.roadAssist}
                        name="roadAssist"
                        onChange={(e) =>
                          this.setState((prevState) => ({
                            sendData: {
                              ...prevState.sendData,
                              roadAssist: e.target.checked,
                            },
                          }))
                        }
                      />
                      <label>24/7 Road Assistance</label>
                    </div>

                    <div>
                      <label>Rate</label>
                      {user.roles.some(
                        (role) => role.name === "Special Agent"
                      ) ? (
                        <select
                          required
                          value={this.state.sendData.commissionRate}
                          className="form-select"
                          name="commissionRate"
                          onChange={(e) => {
                            this.setState((prevState) => ({
                              sendData: {
                                ...prevState.sendData,
                                commissionRate: !e.target.value
                                  ? this.state.sendData.commissionRate
                                  : e.target.value,
                              },
                            }));
                          }}
                        >
                          <option disabled={this.state.sendData.commissionRate}>
                            Select rate
                          </option>
                          {this.state.sendData.commissionRate && (
                            <option>
                              {this.state.sendData.commissionRate}
                            </option>
                          )}
                          {this.state.premiumRateList.map((rate) => (
                            <option key={rate}>{rate}</option>
                          ))}
                        </select>
                      ) : (
                        <select
                          required
                          value={this.state.sendData.commissionRate}
                          className="form-select"
                          name="commissionRate"
                          onChange={(e) => {
                            this.setState((prevState) => ({
                              sendData: {
                                ...prevState.sendData,
                                commissionRate: !e.target.value
                                  ? this.state.sendData.commissionRate
                                  : e.target.value,
                              },
                            }));
                          }}
                        >
                          <option disabled={this.state.sendData.commissionRate}>
                            Select rate
                          </option>
                          {this.state.sendData.commissionRate && (
                            <option>
                              {this.state.sendData.commissionRate}
                            </option>
                          )}
                          {this.state.premiumRateList.map((rate) => (
                            <option key={rate}>{rate}</option>
                          ))}
                        </select>
                      )}
                    </div>

                    {/* <div>
											<label>Vehicle Type</label>
											{
												<select required className="form-select" value={this.state.sendData.vehicleType} onChange={(e) => this.setState(prevState => ({
													sendData: {
														...prevState.sendData,
														vehicleType: e.target.value
													}
												}))}>
													<option>Select vehicle type</option>
													{this.state.sendData.vehicleType && <option>{this.state.sendData.vehicleType}</option>}
													{
														this.state.vehicleTypeList.length > 0 && this.state.vehicleTypeList.map((item, index) => (
															<option key={item.name} value={this.state.vehicleTypeListVal[index]}>{item.name}</option>
														))
													}
												</select>
											}

										</div> */}
                  </div>
                  <div className="d-flex gap-2">
                    <p
                      className="btn fs-sm me-3 text-white"
                      style={{ backgroundColor: "#1A8326" }}
                      onClick={() => {
                        const {
                          brand,
                          commissionRate,
                          model,
                          variant,
                          year,
                          vehicleType,
                        } = this.state.sendData;
                        if (
                          brand &&
                          commissionRate &&
                          model &&
                          variant &&
                          year &&
                          vehicleType
                        ) {
                          const saveLocal = {
                            ...this.state.sendData,
                            fvm: this.state.fvm,
                            id: this.state.id,
                          };
                          localStorage.setItem(
                            "quote",
                            JSON.stringify(saveLocal)
                          ); // Use JSON.stringify here
                          this.navigateTo("/quotations/computation/edit");
                        } else {
                          this.showError("Fill up all field", "Empty values");
                        }
                      }}
                    >
                      <i className="bi bi-file-earmark-check me-2"></i>COMPUTE
                    </p>

                    <p
                      type="button"
                      className="btn btn-light fs-sm"
                      onClick={() => {
                        window.location.replace("/quotations");
                      }}
                    >
                      CANCEL
                    </p>

                    <div>
                      <p
                        className="btn btn-secondary shadow-lg"
                        onClick={this.resetForm.bind(this)}
                      >
                        <i class="bi bi-arrow-clockwise"></i>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(QuatationFormPage);
