import React from "react";
import getIndexes from "../../../../getIndexes";

const defaultProps = {
  schemas: [],
};

function OutputPointer({ field, fields, object, target, schemas, indexes }) {
  const schema = schemas.find((s) => s.collection === target) || {};
  const _fields = fields || schema.fields || {};

  // useMemo is called at the top level, not conditionally
  const _indexes = React.useMemo(() => {
    const items = getIndexes(_fields);
    return items.length > 0 ? items : ["name", "fullName"];
  }, [_fields]);

  const pointer = object[field];
  if (pointer) {
    return _indexes.map((index) => pointer[index]).join(" ");
  }
  return null;
}

OutputPointer.defaultProps = defaultProps;
export default OutputPointer;
