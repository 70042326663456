import { browseFile } from "nq";
import BaseListPresenter from "../../../base/BaseListPresenter";
import { Progress, dialog } from "nq-component";

class MotorCarListPresenter extends BaseListPresenter {
  constructor(
    view,
    findObjectUseCase,
    countObjectUseCase,
    upsertUseCase,
    deleteObjectUseCase,
    updateSchemaUseCase
  ) {
    super(
      view,
      findObjectUseCase,
      countObjectUseCase,
      upsertUseCase,
      deleteObjectUseCase
    );
    this.upsertUseCase = upsertUseCase;
    this.deleteObjectUseCase = deleteObjectUseCase;
    this.updateSchemaUseCase = updateSchemaUseCase;
  }

  init() {
    this.limit = 20;
    this.where = {};
    this.search = {};
    this.filter = {};
    this.filterDate = {};
    this.filterList = {};
    this.filterStatusMake = {};
    this.filterStatusCompany = {};
    this.filterStatusVariant = {};
    this.filterStatusVehicle = {};
    this.filterStatus = {};
    this.filterVehicle = {};
    this.include = ["all"];
    this.keys = [];
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();
  }

  createQuery() {
    const skip = (this.current - 1) * this.limit;
    const query = {
      limit: this.limit,
      skip: skip,
      where: {
        ...this.where,
        ...this.search,
        ...this.filter,
        ...this.filterDate,
        ...this.filterList,
        ...this.filterStatusMake,
        ...this.filterStatusCompany,
        ...this.filterStatusVariant,
        ...this.filterStatus,
        ...this.filterVehicle,
      },

      include: this.include,
    };
    if (this.sort) {
      query.sort = this.sort;
    }
    const keys = this.keys || this.view.getKeys() || [];
    if (keys.length > 0) {
      query.keys = keys;
    }
    return query;
  }

  async findObjects() {
    const user = this.view.getCurrentUser();
    const collection = this.view.getCollectionName();
    let query;

    query = this.createQuery();

    try {
      this.showProgress();
      this.findObjectUseCase.abort();
      const objects = await this.findObjectUseCase.execute(collection, query);
      this.objects = this.objects.concat(objects);
      this.view.setTotal(this.objects.length);
      this.view.setObjects(this.objects);
      this.hideProgress();
    } catch (error) {
      this.hideProgress();
      this.view.showError(error);
    }
    this.progress = false;
  }
  filterListing(where) {
    this.reset();
    this.filterList = where;
    this.getObjects();
  }

  onChangeDate(where) {
    this.filterDate = where;
    this.getObjects();
  }

  onChangeObject(objects) {
    this.change = objects;
    this.object = objects;
    console.log("this.change", this.change);
    console.log("this.object", this.object);
  }

  onClickAdd() {
    const collection = this.view.getCollectionName();
    this.view.navigateTo("/motor-cars/form");
  }

  onClickItem(index) {
    const object = this.objects[index];
    const collection = this.view.getCollectionName();
    this.view.navigateTo("/motor-cars/form/" + object.id);
  }
  onClickExport() {
    this.view.exportPDF();
  }

  async onClickStatus(index, status) {
    const collection = this.view.getCollectionName();
    const object = this.objects[index];
    object.status = status;
    try {
      const data = await this.upsertUseCase.execute(collection, object);
      if (data.status === object.status) {
        this.objects[index] = object;
        this.view.setObjects(this.objects);
      }
    } catch (error) {
      this.view.showError(error);
    }
  }

  // async onClickImportSendDB(data) {
  //   console.log("send data:", data);
  //   const collection = this.view.getCollectionName();

  //   try {
  //     // Fetch the existing data from the collection
  //     const existingData = await this.view.getObjects();

  //     // Create an array to hold the promises for upsert operations
  //     const upsertPromises = [];

  //     // Iterate over each nested array
  //     for (const nestedArray of data) {
  //       // Iterate over each object in the nested array
  //       for (const item of nestedArray) {
  //         // Check if the id is not an empty string
  //         if (item.id && item.id.trim() !== "") {
  //           // Push the upsert promise to the array
  //           upsertPromises.push(this.upsertUseCase.execute(collection, item));
  //         } else {
  //           // If id is an empty string, remove the id field
  //           const { id, ...itemWithoutId } = item;
  //           // Push the upsert promise to the array without id
  //           upsertPromises.push(
  //             this.upsertUseCase.execute(collection, itemWithoutId)
  //           );
  //         }
  //       }
  //     }

  //     // Await all upsert operations to complete
  //     const newResults = await Promise.all(upsertPromises);

  //     // Merge the existing data with the new results
  //     this.objects = [...newResults, ...existingData];
  //     this.view.setObjects(this.objects);
  //   } catch (error) {
  //     this.view.showError(error);
  //   }
  // }

  async onClickImportSendDB(data) {
    console.log("send data:", data);
    const collection = this.view.getCollectionName();

    // Show the processing modal
    this.showModal("Processing... Please Wait...");

    try {
      // Fetch the existing data from the collection
      const existingData = await this.view.getObjects();

      // Create an array to hold the promises for upsert operations
      const upsertPromises = [];

      // Iterate over each nested array
      for (const nestedArray of data) {
        // Iterate over each object in the nested array
        for (const item of nestedArray) {
          // Check if the id is not an empty string
          if (item.id && item.id.trim() !== "") {
            // Push the upsert promise to the array
            upsertPromises.push(this.upsertUseCase.execute(collection, item));
          } else {
            // If id is an empty string, remove the id field
            const { id, ...itemWithoutId } = item;
            // Push the upsert promise to the array without id
            upsertPromises.push(
              this.upsertUseCase.execute(collection, itemWithoutId)
            );
          }
        }
      }
      const newResults = await Promise.all(upsertPromises);
      this.objects = [...newResults, ...existingData];
      this.view.setObjects(this.objects);
    } catch (error) {
      this.view.showError(error);
    } finally {
      setTimeout(() => {
        dialog.close();
        this.hideModal();
      }, 1000); // Adjust the delay as needed
    }
  }

  showModal(message) {
    dialog.fire({
      html: (
        <div>
          <i class="bi bi-hourglass-split" style={{ color: "#014900" }}>
            Processing
          </i>
          <div className=" mt-3">
            <h6>{message}</h6>
          </div>
        </div>
      ),
      footer: false,
    });
  }

  hideModal() {
    dialog.fire({
      html: (
        <div style={{ textAlign: "center" }}>
          <i className="bi bi-ui-checks" style={{ color: "#014900" }}>
            Data has been successfully imported.
          </i>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              onClick={() => this.view.reload()}
              className="btn mb-3 mt-3"
              style={{
                backgroundColor: "#014900",
                color: "white",
                border: "none",
                padding: "0.5rem 1rem",
                cursor: "pointer",
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      ),
      footer: false,
    });
  }

  // USE THIS WITH UTMOST CAUTION
  // async onClickACTIVATESelected(status) {
  //   const selected = this.view.getSelected();
  //   const collection = this.view.getCollectionName();
  //   try {
  //     for (const obj of selected) {
  //       const updatedObj = { ...obj, status: status };
  //       const data = await this.upsertUseCase.execute(collection, updatedObj);
  //       if (data.status === status) {
  //         const index = this.objects.indexOf(obj);
  //         this.objects[index] = updatedObj;
  //       }
  //     }
  //     this.view.setObjects(this.objects);
  //     this.view.setSelected([]);
  //   } catch (error) {
  //     this.view.showError(error);
  //   }
  // }

  onSubmitEditCollection(schema) {
    this.view.closeDialog();
    this.updateSchemaUseCase
      .execute(schema)
      .then((schema) => {
        const schemas = this.view.getSchemas();
        const index = schemas.findIndex(
          (s) => s.collection === schema.collection
        );
        schemas[index] = schema;
        this.view.setSchemas(schemas);
      })
      .catch((error) => {
        this.view.showError(error);
      });
  }

  onChangeStatusMake(value) {
    this.reset();
    this.filterStatusMake = { make: value?.value };
    this.getObjects();
  }
  onChangeStatusCompany(value) {
    this.reset();
    this.filterStatusCompany = { carCompany: value?.value };
    this.getObjects();
  }
  onChangeStatusVariant(value) {
    this.reset();
    this.filterStatusVariant = { carVariant: value?.value };
    this.getObjects();
  }

  onChangeStatus(value) {
    this.reset();
    this.filterStatus = { status: value?.value };
    this.getObjects();
  }

  onChangeVehicle(value) {
    this.reset();
    this.filterStatus = { status: value?.value };
    this.getObjects();
  }

  async onClickDeleteSelected() {
    const selected = this.view.getMotorObjects();
    const collection = this.view.getCollectionName();
    try {
      // await this.view.showDialog({
      //   title: "Delete Data?",
      //   message: "Are you sure you want to delete?",
      // });

      this.showModalDelete(
        "Processing the deletion. So can Import new data. Please wait..."
      );
      for (const obj of selected) {
        await this.deleteObjectUseCase.execute(collection, obj.id);
        const index = this.objects.indexOf(obj);
        this.objects.splice(index, 1);
        this.view.setObjects(this.objects);
      }
      this.hideModalDelete();
      // this.view.setSelected([]);
    } catch (error) {
      this.view.hideProgress();
      this.view.showError(error);
    }
  }

  showModalDelete(message) {
    dialog.fire({
      html: (
        <div>
          <i class="bi bi-hourglass-split ml-2" style={{ color: "#014900" }}>
            Processing
          </i>
          <div className="mt-3 mb-4">
            <h6>{message}</h6>
          </div>
          <div className="mb-3">
            <Progress />
          </div>
        </div>
      ),
      footer: false,
    });
  }

  hideModalDelete() {
    dialog.close();
    dialog.fire({
      html: (
        <div style={{ textAlign: "center" }}>
          <i className="bi bi-ui-checks" style={{ color: "#014900" }}>
            All data has been successfully deleted. Importing the new set of
            data will follow...
          </i>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              onClick={() => this.view.showImport()}
              className="btn mb-3 mt-3"
              style={{
                backgroundColor: "#014900",
                color: "white",
                border: "none",
                padding: "0.5rem 1rem",
                cursor: "pointer",
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      ),
      footer: false,
    });
  }
}

export default MotorCarListPresenter;
