import { browseFile } from "nq";
import BaseListPresenter from "../../../base/BaseListPresenter";

class UserListPresenter extends BaseListPresenter {
  constructor(
    view,
    findObjectUseCase,
    countObjectUseCase,
    upsertUseCase,
    deleteObjectUseCase
  ) {
    super(
      view,
      findObjectUseCase,
      countObjectUseCase,
      upsertUseCase,
      deleteObjectUseCase
    );
    this.upsertUseCase = upsertUseCase;
    this.deleteObjectUseCase = deleteObjectUseCase;
  }

  init() {
    this.limit = 20;
    this.where = {};
    this.search = {};
    this.filter = {};
    this.filterDate = {};
    this.filterList = {};
    this.filterStatus = {};
    this.include = ["all"];
    this.keys = [];
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();
  }

  createQuery() {
    const skip = (this.current - 1) * this.limit;
    const query = {
      limit: this.limit,
      skip: skip,
      where: {
        ...this.where,
        ...this.search,
        ...this.filter,
        ...this.filterDate,
        ...this.filterList,
        ...this.filterStatus,
      },

      include: this.include,
    };
    if (this.sort) {
      query.sort = this.sort;
    }
    const keys = this.keys || this.view.getKeys() || [];
    if (keys.length > 0) {
      query.keys = keys;
    }
    return query;
  }

  async findObjects() {
    const user = this.view.getCurrentUser();
    const collection = this.view.getCollectionName();
    let query;

    query = this.createQuery();

    try {
      this.showProgress();
      this.findObjectUseCase.abort();
      this.objects = [];
      this.view.setObjects([]);
      const objects = await this.findObjectUseCase.execute(collection, query);
      this.objects = this.objects.concat(objects);
      this.view.setTotal(this.objects.length);
      this.view.setObjects(this.objects);
      this.hideProgress();
    } catch (error) {
      this.hideProgress();
      this.view.showError(error);
    }
    this.progress = false;
  }
  filterListing(where) {
    this.reset();
    this.filterList = where;
    this.getObjects();
  }

  onChangeDate(where) {
    this.filterDate = where;
    this.getObjects();
  }

  onChangeObject(objects) {
    this.change = objects;
    this.object = objects;
    console.log("this.change", this.change);
    console.log("this.object", this.object);
  }

  onClickAdd() {
    const collection = this.view.getCollectionName();
    this.view.navigateTo("/users/form");
  }

  onClickItem(index) {
    const object = this.objects[index];
    const collection = this.view.getCollectionName();
    this.view.navigateTo("/users/form/" + object.id);
  }
  onClickExport() {
    this.view.exportPDF();
  }

  async onClickStatus(index, status) {
    const collection = this.view.getCollectionName();
    const object = this.objects[index];
    object.status = status;
    try {
      const data = await this.upsertUseCase.execute(collection, object);
      if (data.status === object.status) {
        this.objects[index] = object;
        this.view.setObjects(this.objects);
      }
    } catch (error) {
      this.view.showError(error);
    }
  }

  // async onClickImportSendDB(data) {
  //   console.log("send data:", data);
  //   const collection = this.view.getCollectionName();
  //   try {
  //     await this.upsertUseCase.execute(collection, data);

  //     this.view.setObjects(this.objects);
  //   } catch (error) {
  //     this.view.showError(error);
  //   }
  // }
  // async onClickImportSendDB(data) {
  //   console.log("send data:", data);
  //   const collection = this.view.getCollectionName();

  //   try {
  //     // Iterate over each nested array
  //     for (const nestedArray of data) {
  //       // Iterate over each object in the nested array
  //       for (const item of nestedArray) {
  //         const data = await this.upsertUseCase.execute(collection, item);
  //       }
  //     }
  //     this.objects = data;
  //     this.view.setObjects(this.objects);
  //   } catch (error) {
  //     this.view.showError(error);
  //   }
  // }
  // async onClickImportSendDB(data) {
  //   console.log("send data:", data);
  //   const collection = this.view.getCollectionName();

  //   try {
  //     // Create an array to hold the promises for upsert operations
  //     const upsertPromises = [];

  //     // Iterate over each nested array
  //     for (const nestedArray of data) {
  //       // Iterate over each object in the nested array
  //       for (const item of nestedArray) {
  //         // Push the upsert promise to the array
  //         upsertPromises.push(this.upsertUseCase.execute(collection, item));
  //       }
  //     }

  //     // Await all upsert operations to complete
  //     const results = await Promise.all(upsertPromises);

  //     // Set the objects with the results
  //     this.objects = results;
  //     this.view.setObjects(this.objects);
  //   } catch (error) {
  //     this.view.showError(error);
  //   }
  // }
  async onClickImportSendDB(data) {
    console.log("send data:", data);
    const collection = this.view.getCollectionName();

    try {
      // Fetch the existing data from the collection
      const existingData = await this.view.getObjects();

      // Create an array to hold the promises for upsert operations
      const upsertPromises = [];

      // Iterate over each nested array
      for (const nestedArray of data) {
        // Iterate over each object in the nested array
        for (const item of nestedArray) {
          // Push the upsert promise to the array
          upsertPromises.push(this.upsertUseCase.execute(collection, item));
        }
      }

      // Await all upsert operations to complete
      const newResults = await Promise.all(upsertPromises);

      // Merge the existing data with the new results
      this.objects = [...newResults, ...existingData];
      this.view.setObjects(this.objects);
    } catch (error) {
      this.view.showError(error);
    }
  }

  onChangeStatus(value) {
    this.reset();
    this.filterStatus = { status: value?.value };
    this.getObjects();
  }
}

export default UserListPresenter;
