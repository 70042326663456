import BaseFormPresenter from "../../base/BaseFormPresenter";

class DeductibleFormPresenter extends BaseFormPresenter {
  onChangeObject(object) {
    this.change = object;
    this.object = object;
  }
}

export default DeductibleFormPresenter;
