import React from "react";
import ConfigurationPresenter from "./ConfigurationPresenter";

import { InfiniteScroll, Button, Progress, NavBar } from "nq-component";
import BaseListPage from "../../base/BaseListPage";
import withRouter from "../../withRouter";
import InputFactory from "../../components/InputFactory";
import {
  countObjectUseCase,
  deleteObjectUseCase,
  findObjectUseCase,
  upsertUseCase,
} from "../../usecases/object";
import Table from "../../components/Table";

class ConfigurationPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new ConfigurationPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase(),
      deleteObjectUseCase(),
      upsertUseCase()
    );
    this.state = {
      selected: [],
      objects: [],
    };
  }
  componentDidMount() {
    this.presenter.componentDidMount();
  }

  onClickItem(index, field) {
    this.presenter.onClickItem(index, field);
  }

  getCollectionName() {
    return "configuration";
  }
  onChangeObject(objects, index) {
    this.presenter.onChangeObject(objects, index);
  }
  onClickAdd() {
    this.presenter.onClickAdd();
  }

  onClickItem(index, field) {
    this.presenter.onClickItem(index, field);
  }

  // delete function
  async handleDeleteData(id) {
    await this.presenter.deleteObjectUseCase.execute("configuration", id);
  }

  // edit function
  async handleEditData(id) {
    this.navigateTo(`/collection/${this.getCollectionName()}/form/${id}`);
  }

  onCollapse(index, object) {
    return (
      <>
        <div className="d-flex">
          <div>
            <button
              className="btn"
              onClick={() => this.onClickItem(index)}
              style={{ backgroundColor: "black", color: "white" }}
            >
              <i className="bi bi-pencil-square me-1"></i>EDIT
            </button>
          </div>
        </div>
      </>
    );
  }

  render() {
    const { isOffcanvasOpen } = this.state;
    const schema = this.getSchema(this.getCollectionName());
    const { objects, selected, count, progress } = this.state;
    console.log("ob", objects);
    const user = this.getCurrentUser();
    const role = this.getCurrentRoles();

    if (!schema) return <Progress />;
    return (
      <>
        {/* <NavBar /> */}
        <div className="overflow-auto">
          <InfiniteScroll
            className="h-100"
            loadMore={this.loadMore.bind(this)}
            hasMore={!progress && count > objects.length}
          >
            <div className="p-3 p-lg-4">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="fw-bold mt-3 text-capitalize">Configuration</h1>

                <div className="d-block d-md-none mt-2">
                  <div
                    className={`offcanvas offcanvas-end ${
                      isOffcanvasOpen ? "show" : ""
                    }`}
                    tabIndex="-1"
                    id="offcanvasRight"
                    aria-labelledby="offcanvasRightLabel"
                    style={{
                      visibility: isOffcanvasOpen ? "visible" : "hidden",
                    }}
                  >
                    <div className="offcanvas-body">
                      <div className=" mt-3">
                        {Object.keys(schema.filters || {}).map((field) => {
                          let { type, ...options } = schema.filters[field];

                          return (
                            <div className="mb-2">
                              <InputFactory
                                key={field}
                                className="ms-1"
                                type={type}
                                field={field}
                                where={{}}
                                {...options}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-md-flex d-none mt-3 justify-content-between align-items-center ">
                <div className="d-flex">
                  {Object.keys(schema.filters || {}).map((field) => {
                    let { type, ...options } = schema.filters[field];

                    return (
                      <InputFactory
                        key={field}
                        className="ms-1"
                        type={type}
                        field={field}
                        where={{}}
                        onChange={this.onChangeFilter.bind(this, type)}
                        {...options}
                      />
                    );
                  })}
                </div>

                {this.state.selected?.length > 0 && (
                  <button
                    className="btn btn-danger"
                    aria-expanded="false"
                    onClick={this.onClickDeleteSelected.bind(this)}
                  >
                    <i className="bi bi-trash"></i>Delete
                  </button>
                )}
              </div>

              <Table
                objectId={this.handleDeleteData.bind(this)}
                editId={this.handleEditData.bind(this)}
                fields={schema.fields}
                objects={objects}
                collection={schema}
                collapsable
                // selectable
                // actionsList={[
                //   {
                //     label: <i className="bi bi-three-dots-vertical" />,
                //     actionType: "common",
                //   },
                // ]}
                // selectable
                // collapsable
                excludeFields={Object.keys(schema.fields).reduce(
                  (acc, key) => {
                    const options = schema.fields[key];
                    if (options.read === false) {
                      acc.push(key);
                    }
                    switch (options._type || options.type) {
                      case "Relation":
                      case "Array":
                      case "Object":
                      case "File":
                        acc.push(key);
                        break;
                      default:
                    }
                    return acc;
                  },
                  ["Id", "Created At", "Updated At"]
                )}
                selected={selected}
                onSelect={this.onSelect.bind(this)}
                onSelectAll={this.onSelectAll.bind(this)}
                onClickItem={this.onClickItem.bind(this)}
                onCollapse={this.onCollapse.bind(this)}
              />
            </div>
            {/* <div className="position-fixed bottom-0 end-0 m-4">
              <Button
                className="btn shadow-sm"
                onClick={this.onClickAdd.bind(this)}
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "25px",
                  backgroundColor: "#014900",
                }}
              >
                <i className="bi bi-plus text-white" />
              </Button>
            </div> */}
          </InfiniteScroll>
        </div>
      </>
    );
  }
}

export default withRouter(ConfigurationPage);
