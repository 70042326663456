import React from "react";
import GroupsListPresenter from "./GroupsListPresenter";
import withRouter from "../../../withRouter";
import NavBar from "../../../components/navbar";
import BaseListPage from "../../../base/BaseListPage";
import InputFactory from "../../../components/InputFactory";
import Table from "../../../components/Table";
import {
  countObjectUseCase,
  deleteObjectUseCase,
  findObjectUseCase,
  upsertUseCase,
} from "../../../usecases/object";
import { exportCSVUseCase } from "../../../usecases/csv/usecases";
import browseFile from "../../../browseFile";
import { InfiniteScroll, Button, Progress } from "nq-component";
import SelectSearch from "../../../components/SelectSearch";

class GroupsListPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new GroupsListPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase(),
      upsertUseCase(),
      deleteObjectUseCase(),
      exportCSVUseCase()
    );
    this.state = {
      isOffcanvasOpen: false,
      objects: [],
      selected: [],
    };
  }
  componentDidMount() {
    this.presenter.componentDidMount();
    this.filterList();
  }
  filterList() {
    const user = this.getCurrentUser();
    const role = this.getCurrentRoles();
    if (role.some((role) => role.name === "admin")) {
      this.presenter.filterListing({});
    } else {
      this.presenter.filterListing({});
    }
  }
  getCollectionName() {
    return "groups";
  }
  onChangeObject(objects, index) {
    this.presenter.onChangeObject(objects, index);
  }
  onClickAdd() {
    this.presenter.onClickAdd();
  }

  onClickItem(index, field) {
    this.presenter.onClickItem(index, field);
  }
  onClickView(index, field) {
    this.presenter.onClickView(index, field);
  }

  onClickImport() {
    browseFile("*").then((files) => {
      if (files.length > 0) {
        const file = files[0];
        this.presenter.onClickImport(file);
      }
    });
  }
  onCollapse(index, object) {
    const user = this.getCurrentUser();
    const role = this.getCurrentRoles();
    return (
      <div>
        <div className="d-flex">
          <ul className="list-unstyled ms-1 text-truncate">
            <li>
              <span className="ms-2 fw-light fw-bold">Group Name: </span>
              <span className="text-nowrap">{object.name}</span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">Status: </span>
              <span className="text-nowrap">{object.status}</span>
            </li>
          </ul>
        </div>
        {/* {user.roles.some(
          (role) => role.name === "Special Agent" || role.name === "Admin"
        ) && ( */}
        <button
          onClick={this.onClickItem.bind(this, index)}
          className="btn btn-primary me-2"
          style={{ backgroundColor: "#014900", border: 0 }}
        >
          EDIT
        </button>
        <button
          onClick={this.onClickView.bind(this, index)}
          className="btn btn-primary me-2"
          style={{ backgroundColor: "#014900", border: 0 }}
        >
          VIEW
        </button>
        {object.status === "INACTIVE" && (
          <button
            onClick={() => this.presenter.onClickStatus(index, "ACTIVE")}
            className="btn btn-primary me-2"
            style={{ backgroundColor: "#FDCB5B", border: 0 }}
          >
            SET ACTIVE
          </button>
        )}
        {object.status === "ACTIVE" && (
          <button
            onClick={() => this.presenter.onClickStatus(index, "INACTIVE")}
            className="btn btn-primary me-2"
            style={{ backgroundColor: "#D4A12D", border: 0 }}
          >
            SET INACTIVE
          </button>
        )}
        {/* )} */}
      </div>
    );
  }
  toggleOffcanvas = () => {
    this.setState((prevState) => ({
      isOffcanvasOpen: !prevState.isOffcanvasOpen,
    }));
  };

  onChangeStatus(value) {
    this.setState({ status: value });
    this.presenter.onChangeStatus(value);
    // this.onChangeFilter("String", value.value, "status");
  }

  onChangeStatusX(value) {
    this.setState({ status: value });
    this.presenter.onChangeStatusX(value);
    // this.onChangeFilter("String", value.value, "status");
  }

  render() {
    const { isOffcanvasOpen } = this.state;
    const schema = this.getSchema(this.getCollectionName());
    const { objects, selected, total, count, progress } = this.state;
    const user = this.getCurrentUser();
    const role = this.getCurrentRoles();

    if (!schema) return <Progress />;
    return (
      <>
        <NavBar />
        <div className="overflow-auto">
          <InfiniteScroll
            className="h-100"
            loadMore={this.loadMore.bind(this)}
            hasMore={!progress && count > objects.length}
          >
            <div className="p-3 p-lg-4">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="fw-bold mt-3 text-capitalize">Groups</h1>
                {selected.length > 0 ? (
                  <div>
                    <span className="ms-2">Selected: </span>
                    <span className="fs-sm text-nowrap">{selected.length}</span>
                    <span className="ms-1">of </span>
                    <span className="fs-sm text-nowrap">{count}</span>
                  </div>
                ) : (
                  <div>
                    <span className="ms-2">Total: </span>
                    <span className="fs-sm text-nowrap">{objects.length}</span>
                    <span className="ms-1">of </span>
                    <span className="fs-sm text-nowrap">{count}</span>
                  </div>
                )}

                <div className="d-block d-md-none mt-2">
                  <div className="d-flex align-items-center ">
                    <i
                      className="bi bi-filter"
                      style={{ fontSize: "25px", color: "#014900" }}
                      onClick={this.toggleOffcanvas}
                      aria-controls="offcanvasRight"
                    ></i>
                  </div>

                  <div
                    className={`offcanvas offcanvas-end ${
                      isOffcanvasOpen ? "show" : ""
                    }`}
                    tabIndex="-1"
                    id="offcanvasRight"
                    aria-labelledby="offcanvasRightLabel"
                    style={{
                      visibility: isOffcanvasOpen ? "visible" : "hidden",
                    }}
                  >
                    <div
                      className="offcanvas-header"
                      style={{ backgroundColor: "#014900" }}
                    >
                      <h5
                        className="offcanvas-title text-white"
                        id="offcanvasRightLabel"
                      >
                        Filters
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={this.toggleOffcanvas}
                      ></button>
                    </div>
                    <div className="offcanvas-body">
                      <div className=" mt-3">
                        {Object.keys(schema.filters || {}).map((field) => {
                          let { type, ...options } = schema.filters[field];

                          return (
                            <div className="mb-2">
                              <InputFactory
                                key={field}
                                className="ms-1"
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter.bind(this, type)}
                                {...options}
                              />
                              <SelectSearch
                                onChange={(value) => this.onChangeStatus(value)}
                                onClickClear={() =>
                                  this.onChangeStatusX({ label: "", value: "" })
                                }
                                value={this.state.status}
                                options={["ACTIVE", "INACTIVE"].map((item) => ({
                                  label: item,
                                  value: item,
                                }))}
                              />
                            </div>
                          );
                        })}

                        {/* <DateRange
                          onChange={this.onChangeDate.bind(this)}
                          field="date"
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-md-flex d-none mt-3 justify-content-between align-items-center ">
                <div className="d-flex">
                  {Object.keys(schema.filters || {}).map((field) => {
                    let { type, ...options } = schema.filters[field];

                    return (
                      <InputFactory
                        key={field}
                        className="ms-1"
                        type={type}
                        field={field}
                        where={{}}
                        onChange={this.onChangeFilter.bind(this, type)}
                        {...options}
                      />
                    );
                  })}

                  <SelectSearch
                    onChange={(value) => this.onChangeStatus(value)}
                    onClickClear={() =>
                      this.onChangeStatusX({ label: "", value: "" })
                    }
                    value={this.state.status}
                    options={["ACTIVE", "INACTIVE"].map((item) => ({
                      label: item,
                      value: item,
                    }))}
                  />

                  {/* <DateRange
                    onChange={this.onChangeDate.bind(this)}
                    field="date"
                  /> */}
                </div>

                {this.state.selected.length > 0 && (
                  <button
                    className="btn btn-danger"
                    aria-expanded="false"
                    onClick={this.onClickDeleteSelected.bind(this)}
                  >
                    <i className="bi bi-trash"></i>Delete
                  </button>
                )}
              </div>

              <Table
                fields={schema.fields}
                groups={schema.groups}
                objects={objects}
                collapsable
                selectable
                excludeFields={Object.keys(schema.fields).reduce(
                  (acc, key) => {
                    const options = schema.fields[key];
                    if (options.read === false) {
                      acc.push(key);
                    }
                    switch (options._type || options.type) {
                      case "Relation":
                      case "Array":
                      case "Object":
                      case "File":
                        acc.push(key);
                        break;
                      default:
                    }
                    return acc;
                  },
                  [
                    "acl",
                    "password",
                    "br",
                    "or",
                    "items",
                    "id",
                    "createdAt",
                    "branchName",
                    "freePassenger",
                    "accountOfficerEmail",
                    "username",
                    "email",
                  ]
                )}
                selected={selected}
                onSelect={this.onSelect.bind(this)}
                onSelectAll={this.onSelectAll.bind(this)}
                progress={progress}
                onClickItem={this.onClickItem.bind(this)}
                onCollapse={this.onCollapse.bind(this)}
                className="mt-3"
              />
            </div>
            <div className="position-fixed bottom-0 end-0 m-4">
              <Button
                className="btn shadow-sm"
                onClick={this.onClickAdd.bind(this)}
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "25px",
                  backgroundColor: "#014900",
                }}
              >
                <i className="bi bi-plus text-white" />
              </Button>
            </div>
          </InfiniteScroll>
        </div>
      </>
    );
  }
}

export default withRouter(GroupsListPage);
