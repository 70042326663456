import { browseFile } from "nq";
import BaseListPresenter from "../../../../base/BaseListPresenter";

class StandardRatesListPresenter extends BaseListPresenter {
  constructor(
    view,
    findObjectUseCase,
    countObjectUseCase,
    upsertUseCase,
    deleteObjectUseCase
  ) {
    super(
      view,
      findObjectUseCase,
      countObjectUseCase,
      upsertUseCase,
      deleteObjectUseCase
    );
    this.upsertUseCase = upsertUseCase;
    this.deleteObjectUseCase = deleteObjectUseCase;
  }

  init() {
    this.limit = 20;
    this.where = {};
    this.search = {};
    this.filter = {};
    this.filterDate = {};
    this.filterList = {};
    this.filterStatus = {};
    this.include = ["all"];
    this.keys = [];
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();
  }

  createQuery() {
    const skip = (this.current - 1) * this.limit;
    const query = {
      limit: this.limit,
      skip: skip,
      where: {
        ...this.where,
        ...this.search,
        ...this.filter,
        ...this.filterDate,
        ...this.filterList,
        ...this.filterStatus,
      },

      include: this.include,
    };
    if (this.sort) {
      query.sort = this.sort;
    }
    const keys = this.keys || this.view.getKeys() || [];
    if (keys.length > 0) {
      query.keys = keys;
    }
    return query;
  }

  async findObjects() {
    const user = this.view.getCurrentUser();
    const collection = this.view.getCollectionName();
    let query;

    query = this.createQuery();

    try {
      this.showProgress();
      this.findObjectUseCase.abort();
      const objects = await this.findObjectUseCase.execute(collection, query);
      this.objects = this.objects.concat(objects);
      this.view.setTotal(this.objects.length);
      this.view.setObjects(this.objects);
      this.hideProgress();
    } catch (error) {
      this.hideProgress();
      this.view.showError(error);
    }
    this.progress = false;
  }
  filterListing(where) {
    this.reset();
    this.filterList = where;
    this.getObjects();
  }

  onChangeDate(where) {
    this.filterDate = where;
    this.getObjects();
  }

  onChangeObject(objects) {
    this.change = objects;
    this.object = objects;
    console.log("this.change", this.change);
    console.log("this.object", this.object);
  }

  onClickAdd() {
    const collection = this.view.getCollectionName();
    switch (collection) {
      case "standard_odta":
        this.view.navigateTo("/standard-rates/od-theft/form");
        break;

      case "standard_others":
        this.view.navigateTo("/standard-rates/others/form");
        break;

      default:
        break;
    }
  }

  onClickItem(index) {
    const object = this.objects[index];
    const collection = this.view.getCollectionName();
    switch (collection) {
      case "standard_odta":
        this.view.navigateTo("/standard-rates/od-theft/form/" + object.id);
        break;

      case "standard_others":
        this.view.navigateTo("/standard-rates/others/form/" + object.id);
        break;

      default:
        break;
    }
  }
  onClickExport() {
    this.view.exportPDF();
  }

  async onClickStatus(index, status) {
    const collection = this.view.getCollectionName();
    const object = this.objects[index];
    object.status = status;
    try {
      const data = await this.upsertUseCase.execute(collection, object);
      if (data.status === object.status) {
        this.objects[index] = object;
        this.view.setObjects(this.objects);
      }
    } catch (error) {
      this.view.showError(error);
    }
  }

  onChangeStatus(value) {
    this.reset();
    this.filterStatus = { status: value?.value };
    this.getObjects();
  }
}

export default StandardRatesListPresenter;
