const defaultProps = {
    keys: []
}

function OutputString({field, object, keys}) {
    const value = object[field];
    if (value && typeof value !== 'object') {
        return value;
    }
    const values = [];
    keys.forEach(key => {
        const v = object[key];
        if (v && typeof v !== 'object') {
            values.push(v);
        }
    })
    return values.join(" ");
}

OutputString.defaultProps = defaultProps;

export default OutputString;
