import { browseFile } from "nq";
import BaseListPresenter from "../../../base/BaseListPresenter";
import FindObjectUseCase from "../../../usecases/object/FindObjectUseCase";
import { restUseCase } from "../../../usecases/rest";
import UpsertUseCase from "../../../usecases/object/UpsertUseCase";

class QuotationsListPresenter extends BaseListPresenter {
  constructor(
    view,
    findObjectUseCase,
    countObjectUseCase,
    upsertUseCase,
    deleteObjectUseCase
  ) {
    super(
      view,
      findObjectUseCase,
      countObjectUseCase,
      upsertUseCase,
      deleteObjectUseCase
    );
    this.upsertUseCase = upsertUseCase;
    this.deleteObjectUseCase = deleteObjectUseCase;
  }

  init() {
    const user = this.view.getCurrentUser();
    this.limit = 20;
    this.where = {
      createdBy: user.email,
    };
    this.search = {};
    this.filter = {};
    this.filterDate = {};
    this.filterList = {};
    this.filterStatus = {};
    this.include = ["all"];
    this.keys = [];
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();
  }

  createQuery() {
    const skip = (this.current - 1) * this.limit;
    const query = {
      limit: this.limit,
      skip: skip,
      where: {
        ...this.where,
        ...this.search,
        ...this.filter,
        ...this.filterDate,
        ...this.filterList,
        ...this.filterStatus,
      },

      include: this.include,
    };
    if (this.sort) {
      query.sort = this.sort;
    }
    const keys = this.keys || this.view.getKeys() || [];
    if (keys.length > 0) {
      query.keys = keys;
    }
    return query;
  }

  async findObjects() {
    const user = this.view.getCurrentUser();
    const collection = this.view.getCollectionName();
    let query;

    query = this.createQuery();

    try {
      this.showProgress();
      this.findObjectUseCase.abort();
      this.objects = [];
      this.view.setObjects([]);
      const objects = await this.findObjectUseCase.execute(collection, query);
      this.objects = this.objects.concat(objects);
      this.view.setTotal(this.objects.length);
      this.view.setObjects(this.objects);
      this.hideProgress();
    } catch (error) {
      this.hideProgress();
      this.view.showError(error);
    }
    this.progress = false;
  }
  filterListing(where) {
    this.reset();
    this.filterList = where;
    this.getObjects();
  }

  onChangeDate(where) {
    this.filterDate = where;
    this.getObjects();
  }

  onChangeObject(objects) {
    this.change = objects;
    this.object = objects;
  }

  onClickAdd() {
    this.view.navigateTo("/quotations/form");
  }

  onClickItem(index, status) {
    const object = this.objects[index];
    console.log(object);
    localStorage.setItem("quote", JSON.stringify(object));
    const collection = this.view.getCollectionName();
    if (status === "sent") {
      this.view.navigateTo("/quotations/computation/edit");
    } else {
      this.view.navigateTo("/quotations/form/" + object.id);
    }
  }

  async sendEmail(name, quoteLINK, object) {
    console.log("quoteLink", quoteLINK);
    const user = this.view.getCurrentUser();

    const method = "POST";
    const path = "/send-email/56c1095c-1447-4e16-8ae7-2a3d795ea952";
    const obj = {
      to: user.accountOfficerEmail,
      name: name,
      body: `${quoteLINK}`,
      subject: "Motor Car Quotation",
    };

    try {
      const res = await restUseCase().execute(method, path, {
        body: obj,
      });

      if (res) {
        this.view.setState({ sendingToOffice: false });

        const save = new UpsertUseCase();

        const saveThisQuote = {
          ...object,
          status: "done",
        };

        save.execute("quotations", saveThisQuote);
        this.view.showSuccess(
          "Successfully sent to Office",
          "successfully sent"
        );
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async onSendOfficer(index) {
    const object = this.objects[index];
    const user = this.view.getCurrentUser();

    this.sendEmail(user.agentName, object.quote_file, object);
  }

  onClickExport() {
    this.view.exportPDF();
  }

  async onClickStatus(index, status) {
    const collection = "quotations";
    const object = this.objects[index];

    object.status =
      status === "SENT" ? "SENT" : "DONE" ? "DONE" : "DRAFT" ? "DRAFT" : status;

    try {
      const data = await this.upsertUseCase.execute(collection, object);
      if (data.status === object.status) {
        this.objects[index] = object;
        this.view.setObjects(this.objects);
      }
    } catch (error) {
      this.view.showError(error);
    }
  }

  onChangeStatus(value) {
    this.reset();
    this.filterStatus = { status: value?.value };
    this.getObjects();
  }
}

export default QuotationsListPresenter;
