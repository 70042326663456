import React from "react";
import Progress from "../Progress";
import classNames from "../../classNames";
import Checkbox from "../Checkbox";

const noop = () => {};
const defaultProps = {
  fields: {},
  objects: [],
  selected: [],
  selectable: false,
  excludeFields: [],
  progress: false,
  onClick: noop,
  onClickItem: noop,
  // onClickStatus: noop,
  onSelect: noop,
  onSelectAll: noop,
  readOnly: false,
  onLongPress: noop,
};

const formatDate = (dateString) => {
  const providedDate = new Date(dateString);
  providedDate.setHours(0, 0, 0, 0);

  const options = { year: "numeric", month: "short", day: "numeric" };
  return providedDate.toLocaleDateString("en-US", options);
};

function CardDeduc({
  className,
  fields,
  user,
  objects,
  progress,
  selected,
  onSelect,
  onSelectAll,
  onClick,
  onClickItem,
  // onClickStatus,
  selectable,
  setRef,
  excludeFields,
  ...props
}) {
  return (
    <div className="position-relative">
      <div
        className={classNames("card mt-2 border-0", className)}
        style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
        ref={setRef}
        {...props}
      >
        {objects.length === 0 && !progress && (
          <h4
            className="text-center fs-lg"
            colSpan={Object.keys(fields).length + 1}
          >
            No Data Found
          </h4>
        )}
        <div className="ms-1 me-1">
          {objects.map((object, index) => {
            const id = object.id || object._id;
            const checked = selected.includes(object);
            return (
              <div key={id} className="card mb-3">
                <div className="card-body mb-0 p-2">
                  <div className="card-title d-flex align-items-center justify-content-between m-0">
                    <h4 className="card-title d-flex align-items-center p-2">
                      {selectable &&
                        user.roles.some(
                          (role) =>
                            role.name === "Admin" || role.name === "hrAdmin"
                        ) && (
                          <div
                            className=""
                            style={{ backgroundColor: "rgba(0,0,0,0)" }}
                          >
                            <Checkbox
                              checked={checked}
                              id={"check_" + id}
                              onChange={() => onSelect(index)}
                            />
                          </div>
                        )}
                      <div className="fw-bold" style={{ color: "#1A8326" }}>
                        {object.vehicleType.name}
                      </div>
                    </h4>
                    {user.roles.some(
                      (role) => role.name === "Admin" || role.name === "hrAdmin"
                    ) && (
                      <div className="d-flex align-items-center p-0">
                        <button
                          className="btn btn-outline-success m-2 fs-6 border-1"
                          onClick={() => onClickItem(index)}
                          style={{ color: "#1A8326BF" }}
                        >
                          EDIT
                        </button>
                        {/* <button
                          className="btn btn-primary m-2 fs-6 border-0"
                          onClick={() => onClickStatus(index, "ACTIVE")}
                          style={{
                            backgroundColor: "#FDCB5B",
                          }}
                        >
                          SET ACTIVE
                        </button>
                        <button
                          className="btn btn-primary m-2 fs-6 border-0"
                          onClick={() => onClickStatus(index, "INACTIVE")}
                          style={{
                            backgroundColor: "#D4A12D",
                          }}
                        >
                          SET INACTIVE
                        </button> */}
                      </div>
                    )}
                  </div>

                  {/* <p className="card-text fs-6 mb-0 p-2">
                    <span className="fw-bold">Vehicle Type: </span>
                    <span style={{}}>{object.vehicleType.name}</span>{" "}
                  </p> */}
                  <p className="card-text fs-6 mb-0 p-2">
                    <span className="fw-bold">Deductible: </span>
                    <span style={{}}>{object.deductible}</span>
                  </p>

                  {object.group && (
                    <p className="card-text fs-6 mb-0 p-2">
                      <span className="fw-bold">
                        {object.group && object.group.length > 1
                          ? "Groups:"
                          : "Group:"}{" "}
                      </span>
                      <span style={{}}>
                        {object.group && object.group.length > 0
                          ? object.group.map((group) => group.name).join(", ")
                          : "No Assigned Group"}
                      </span>
                    </p>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        {progress && (
          <div colSpan={Object.keys(fields).length + 1}>
            <Progress className="fs-sm">Loading ...</Progress>
          </div>
        )}
      </div>
    </div>
  );
}

CardDeduc.defaultProps = defaultProps;
export default CardDeduc;
