import React from "react";
import MainPagePresenter from "./MainPagePresenter";
import { Menu } from "nq-component";
import { getAllSchemasUseCase } from "../../usecases/schema/usecases";
import { getCurrentUserUseCase, signOutUseCase } from "../../usecases/user";
import { Routes, Route } from "react-router-dom";
import { OffCanvas } from "nq-component";
import CollectionListPage from "../collection-list/CollectionListPage";
import CollectionFormPage from "../collection-form/CollectionFormPage";
import BasePage from "../../base/BasePage";
import NotFoundPage from "../notfound";
import { Layout, Progress } from "nq-component";
import MigrationPage from "../migration/MigrationPage";
import AccountPage from "../account/AccountPage";
import RoleFormPage from "../role-form/RoleFormPage";
import canRead from "../../canRead";
import withRouter from "../../withRouter";
import DashboardPage from "../dashboard/DashboardPage";
import HooksPage from "../web-hook/HooksPage";
import FunctionPage from "../web-hook/FunctionPage";
import SchemaPage from "../schema/SchemaPage";
import getMenu from "../getMenu";
import menus from "./menus.json";
import UserListPage from "../user/UserList/UserListPage";
import UserFormPage from "../user/UserForm/UserFormPage";
import MotorCarListPage from "../motor-car/MotorCarList/MotorCarListPage";
import MotorCarFormPage from "../motor-car/MotorCarForm/MotorCarFormPage";
import BranchListPage from "../branch/BranchList/BranchListPage";
import BranchFormPage from "../branch/BranchForm/BranchFormPage";
import TransactionListPage from "../transactions/TransactionList/TransactionListPage";
import LogoHolder from "../../components/LogoHolder";
import QuotationsListPage from "../quotations/QuotationsList/QuotationsListPage";
import HomeQuotationListPage from "../home/HomeQuotationsList/QuotationsListPage";
import StandardRatesListPage from "../rates/StandardRates/StandardRatesList/StandardRatesListPage";
import SpecialRatesListPage from "../rates/SpecialRates/SpecialRatesList/SpecialRatesListPage";
import StandardRatesFormPage from "../rates/StandardRates/StandardRatesForm/StandardRatesFormPage";
import SpecialRatesFormPage from "../rates/SpecialRates/SpecialRatesForm/SpecialRatesFormPage";
import GroupsListPage from "../groups/GroupsList/GroupsListPage";
import GroupsFormPage from "../groups/GroupsForm/GroupsFormPage";
import GroupsUsersPage from "../groups/GroupsUsers/GroupsUsersPage";
import QuatationFormPage from "../quotations/QuatationForm/QuatationFormPage";
import HomeQuatationFormPage from "../home/QuatationForm/HomeQuatationFormPage";
import ComputeNewPage from "../quotations/ComputeNew/ComputeNewPage";
import ConfigurationPage from "../configuration/ConfigurationPage";
import ClientInfoFormPage from "../quotations/ClientInfo/ClientInfoFormPage";
import ClientInformation from "../quotations/ClientInformatino/ClientInformation";
import DeductiblePage from "../deductible/DeductiblePage";
import DeductibleFormPage from "../deductible/DeductibleFormPage";

class MainPage extends BasePage {
  constructor(props) {
    super(props);
    this.presenter = new MainPagePresenter(
      this,
      getCurrentUserUseCase(),
      signOutUseCase(),
      getAllSchemasUseCase()
    );
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  onClickSignOut() {
    this.presenter.onClickSignOut();
  }

  onClickMenu(e, item) {
    e.preventDefault();
    this.navigateTo(item.route);
  }

  render() {
    const user = this.getCurrentUser();
    console.log("MAIN USER", user);
    const schemas = this.getSchemas();
    const roles = this.getCurrentRoles();
    if (!user || !schemas || !roles) {
      console.log("Progress");
      return <Progress />;
    }
    const settings = [
      {
        name: "Edit Account",
        route: "/account",
        icon: "bi bi-person-check",
      },
      // {
      //     name: "Notification",
      //     route: "/notification",
      //     icon: "bi bi-bell"
      // },
    ];

    const hook = [
      {
        name: "Hooks",
        route: "/hooks",
        icon: "bi bi-person-check",
      },
      {
        name: "Function",
        route: "/function",
        icon: "bi bi-person-check",
      },
    ];

    const setting = {
      name: "Settings",
      icon: "bi bi-sliders",
      route: settings,
    };

    // const menus = [
    //   ...schemas
    //     .sort(
    //       (a, b) =>
    //         (a.index || Number.POSITIVE_INFINITY) -
    //         (b.index || Number.POSITIVE_INFINITY)
    //     )
    //     .map((s) => ({
    //       name: s.label || s.collection || s.name,
    //       icon: s.icon,
    //       route: s.route || "/collection/" + s.collection || s.name,
    //     })),
    //   hooksMenu,
    //   setting,
    // ];
    return (
      <Layout>
        <Layout.Context.Consumer>
          {(value) => (
            <OffCanvas onSetShow={value.setCollapse} show={value.collapsed}>
              <div
                className="offcanvas-body"
                style={{ backgroundColor: "white" }}
              >
                <nav className="navbar-whie">
                  <div className="text-center">
                    <LogoHolder
                      className="bg-dark"
                      textClassName="text-white"
                      // logo={user.picture ? user.picture : "/Online-logo.png"}
                      logo={"/Online-logo.png"}
                      name={user.username}
                    />

                    <p className="text-dark mt-3">
                      {/* {user.name || user.username} */}
                      {user.agentName}
                    </p>
                  </div>
                  <hr className="dropdown-divider bg-light" />
                  <Menu
                    onClickItem={this.onClickMenu.bind(this)}
                    menus={getMenu(menus, roles[0]?.name)}
                  />
                </nav>
              </div>
              <div className="bg-white">
                <button
                  className="nav-link text-dark btn btn-link m-3"
                  onClick={this.onClickSignOut.bind(this)}
                >
                  <i className="bi bi-power"></i>
                  <span className="ms-2 fw-bold small">Log out</span>
                </button>
              </div>
            </OffCanvas>
          )}
        </Layout.Context.Consumer>
        <main
          className="vh-100 d-flex flex-column"
          style={{ backgroundColor: "#F1F1F1" }}
        >
          <Routes>
            <Route
              path={"/"}
              element={
                user.role === "Admin" ? <UserListPage /> : <QuatationFormPage />
              }
            />
            {/*<Route*/}
            {/*  exact*/}
            {/*  path={"/collection/dashboard"}*/}
            {/*  element={<DashboardPage />}*/}
            {/*/>*/}
            <Route
              exact
              path={"/collection/:name"}
              element={<CollectionListPage />}
            />
            <Route path={"/collection/roles/form"} element={<RoleFormPage />} />
            <Route
              path={"/collection/roles/form/:id"}
              element={<RoleFormPage />}
            />
            <Route
              path={"/collection/:name/form/"}
              element={<CollectionFormPage />}
            />
            <Route
              path={"/collection/:name/form/:id"}
              element={<CollectionFormPage />}
            />
            <Route path={"/migration"} element={<MigrationPage />} />
            <Route path={"/schema"} element={<SchemaPage />} />
            <Route path={"/account"} element={<AccountPage />} />
            <Route path={"/hooks"} element={<HooksPage />} />
            <Route path={"/function"} element={<FunctionPage />} />

            {/* Users Collection */}
            <Route path={"/users"} element={<UserListPage />} />
            <Route path={"/users/form"} element={<UserFormPage />} />
            <Route path={"/users/form/:id"} element={<UserFormPage />} />

            {/* Groups Collection */}
            <Route path={"/groups"} element={<GroupsListPage />} />
            <Route path={"/groups/form"} element={<GroupsFormPage />} />
            <Route path={"/groups/form/:id"} element={<GroupsFormPage />} />
            <Route
              path={"/groups/users/:name/:id"}
              element={<GroupsUsersPage />}
            />

            {/* Standard Rates */}
            <Route
              path={"/standard-rates/:rate"}
              element={<StandardRatesListPage />}
            />
            <Route
              path={"/standard-rates/:rate/form"}
              element={<StandardRatesFormPage />}
            />
            <Route
              path={"/standard-rates/:rate/form/:id"}
              element={<StandardRatesFormPage />}
            />

            {/* Special Rates */}
            <Route
              path={"/special-rates/:rate"}
              element={<SpecialRatesListPage />}
            />
            <Route
              path={"/special-rates/:rate/form"}
              element={<SpecialRatesFormPage />}
            />
            <Route
              path={"/special-rates/:rate/form/:id"}
              element={<SpecialRatesFormPage />}
            />

            {/* Motor Cars Collection */}
            <Route path={"/motor-cars"} element={<MotorCarListPage />} />
            <Route path={"/motor-cars/form"} element={<MotorCarFormPage />} />
            <Route
              path={"/motor-cars/form/:id"}
              element={<MotorCarFormPage />}
            />

            {/* Branches Collection */}
            <Route path={"/branches"} element={<BranchListPage />} />
            <Route path={"/branches/form"} element={<BranchFormPage />} />
            <Route path={"/branches/form/:id"} element={<BranchFormPage />} />

            {/* Transactions Collection */}
            <Route path={"/transactions"} element={<TransactionListPage />} />

            {/* Quotations Collection */}
            <Route path={"/quotations"} element={<QuotationsListPage />} />
            <Route path={"/quotations/form"} element={<QuatationFormPage />} />
            <Route
              path={"/quotations/form/:id"}
              element={<QuatationFormPage />}
            />

            <Route
              path={"/quotations/computation/edit"}
              element={<ComputeNewPage />}
            />

            <Route
              path={"/quotations/client_info/form"}
              element={<ClientInfoFormPage />}
            />
            <Route
              path={"/quotations/client_info/forms"}
              element={<ClientInformation />}
            />

            {/* Home Collection */}
            {/* <Route path={"/home"} element={<HomeQuotationListPage />} /> */}
            <Route path={"/home"} element={<HomeQuatationFormPage />} />

            {/* <Route path={"/users/form"} element={<UserFormPage />} />
            <Route path={"/users/form/:id"} element={<UserFormPage />} /> */}

            <Route path={"/configuration"} element={<ConfigurationPage />} />
            <Route path={"/deductible"} element={<DeductiblePage />} />
            <Route path={"/deductible/form"} element={<DeductibleFormPage />} />
            <Route
              path={"/deductible/form/:id"}
              element={<DeductibleFormPage />}
            />

            <Route element={<NotFoundPage />} />
          </Routes>
        </main>
      </Layout>
    );
  }
}

export default withRouter(MainPage);
